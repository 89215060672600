import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FTK } from "../Lottie/FTK";

const LoadingPage = () => {
  // variants
  const containerMotion = {
    visible: {
      background: "transparent",
      transition: {
        delay: 2,
      },
    },
  };

  const secondChildMotion = {
    hidden: {},
    visible: {
      opacity: 0,
      transition: {
        duration: 0,
        type: "spring",
        delay: 0,
        opacity: { delay: 4.5 },
      },
    },
  };

  const fifthChildMotion = {
    visible: { opacity: 0, transition: { delay: 4.5 } },
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let intreval = setInterval(() => {
      if (progress < 100) {
        setProgress((prev) => prev + 5);
      }
    }, 100);
    return () => {
      clearInterval(intreval);
    };
  }, [progress]);
  return (
    <div>
      <motion.div layout style={{ overflow: "hidden" }}>
        <motion.div
          layout
          className="loading-page"
          variants={containerMotion}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            layout
            variants={secondChildMotion}
            className="loading-page__text-container"
          >
            <FTK />
          </motion.div>

          <motion.span
            layout
            transition={{ ease: "linear" }}
            variants={fifthChildMotion}
            className="loading-page__text"
          >
            {progress}%
          </motion.span>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default LoadingPage;
