import { Fragment, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import { useDispatch, useSelector } from "react-redux";
import { PrivacyAction } from "../State/Features/Privacy/privacy";
import FooterSection from "../Compoents/FooterSection";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";

const Privacy = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { locate, footer } = useSelector((state) => state.ui);
  const { dataPrivacy } = useSelector((state) => state.privacy);
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });

  useEffect(() => {
    dispatch(PrivacyAction(`privacy?${locate}`));
  }, [ref.current]);
  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );
  useEffect(() => {
    if (dataPrivacy) {
      const seo = dataPrivacy?.data?.attributes.SEO;
      setDataFetch({
        body: dataPrivacy?.data?.attributes,
        seo: seo,
      });
    }
  }, [dataPrivacy]);
  return (
    <Fragment>
      {dataFetch.body && (
        <>
          <Header data={footer} />
          <div className="policy-privacy" ref={ref}>
            <ReactMarkdown
              className="markdown-policy-privacy"
              rehypePlugins={rehypeRaw}
            >
              {dataFetch.body.Terms}
            </ReactMarkdown>
          </div>
          <FooterSection data={footer} />
        </>
      )}
      <Transition />
    </Fragment>
  );
};

export default Privacy;
