import WordList from "./WordList";

const WordListContainer = ({ keywords }) => (
  <div className="list-holder" style={{ height: "100%" }}>
    {keywords?.map((e, index) => (
      <WordList key={index} index={index} word={e.word} />
    ))}
  </div>
);

export default WordListContainer;
