// BlogGSAP.js
import { useEffect } from "react";
import gsap from "gsap";
import { changeMenuColorBlack } from "../../MotionTools/Slide";

const BlogGSAP = ({ blogRef }) => {
  useEffect(() => {
    const cardHeight = document.querySelector(".card-container")?.clientHeight;
    changeMenuColorBlack(blogRef.current, "white");

    const tl = gsap.timeline({ defaults: {} });
    const motions = () => {
      let mm = gsap.matchMedia();
      const cards = gsap.utils.toArray(".card-container");

      mm.add("(max-width:700px)", () => {
        cards.forEach((el, index) => {
          tl.fromTo(
            el,
            { y: `${700 * (index + 1.5)}` },
            {
              y: `${30 * (index + 1)}`,
              scrollTrigger: {
                trigger: ".blog",
                start: `+=${300 * (index + 1)} 80%`,
                end: `+=${100 * (index + 7)}`,
                scrub: true,
              },
            }
          );
        });
      });

      mm.add("(min-width:701px)", () => {
        cards.forEach((el, index) => {
          tl.fromTo(
            el,
            { y: (window.innerHeight / 1.5) * (index + 2) },
            {
              y: cardHeight * 0.1 * (index + 1),
              scrollTrigger: {
                trigger: ".blog",
                start: `+=${cardHeight * 0.4 * (index + 1)}`,
                end: `+=${blogRef.current?.offsetHeight * 0.15 * (index + 2)}`,
                scrub: true,
                invalidateOnRefresh: true,
              },
            }
          );
        });
      });
    };

    window.addEventListener("resize", motions);
    motions();
    return () => {
      window.removeEventListener("resize", motions);
    };
  }, [blogRef]);

  return null;
};

export default BlogGSAP;
