import { memo, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import StratogyContent from "./StratogyContent";
import WordListContainer from "./WordListContainer";
import ScrollAnimation from "./ScrollAnimation";

gsap.registerPlugin(ScrollTrigger);

const StratogySection = () => {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const [height, setHeight] = useState(null);
  const ref = useRef(null);
  const { statusHome, dataHome } = useSelector((state) => state.home);

  useEffect(() => {
    if (dataHome && dataHome.data && dataHome.data.attributes) {
      const body = dataHome.data.attributes.body.filter(
        (e) => e.__component === "page.strategy"
      );
      const seo =
        dataHome.attributes && dataHome.attributes.SEO
          ? dataHome.attributes.SEO
          : null;

      setDataFetch({
        body: body.length > 0 ? body[0] : null,
        seo: seo,
      });
    }
  }, [dataHome]);

  return (
    <motion.section ref={ref} className="stratogy__container">
      <motion.div
        className="items-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignSelf: "flex-start",
          height: "100%",
          flexBasis: "85%",
          position: "relative",
        }}
      >
        <StratogyContent dataFetch={dataFetch} />
        <WordListContainer keywords={dataFetch.body?.keywords} />
      </motion.div>
      <ScrollAnimation refpass={ref} />
    </motion.section>
  );
};

export default memo(StratogySection);
