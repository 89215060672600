import LoadingPage from "../Compoents/Home/LoadingPage";

import MainLandSection from "../Compoents/Home/MainLandSection/MainLandSection";
import { motion } from "framer-motion";
import EsperienzaSection from "../Compoents/Home/EsperienzaSection/EsperienzaSection";
import { useEffect, useRef, useState } from "react";
import StratogySection from "../Compoents/Home/StratogySection/StratogySection";

import ContactSection from "../Compoents/Home/ContactSection/ContactSection";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { useSelector } from "react-redux";

import BlogSection from "../Compoents/Home/BlogSection/BlogSection";
import Approccio from "../Compoents/Home/ApproccioSection/ApproccioSection";
import { lenis } from "../App";

import ProjectSection from "../Compoents/Home/PtojectSection/ProjectSection";
import { useLocation, useNavigate } from "react-router-dom";

import CrlBtn from "../Compoents/GeneralComponents/ClrBtn";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  // squence loading
  const [isPreLoader, setIsPreLoader] = useState(false);
  const [loadT, setLoadT] = useState(false);
  const ref = useRef(null);

  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate, isLoading, footer } = useSelector((state) => state.ui);
  const [dataFetch, setDataFetch] = useState({});
  const nav = useNavigate();
  const mobile = useMediaQuery({ maxWidth: 700 });

  const reactRouterHistory = useLocation();

  const [loading, setLoading] = useState(true);

  useGSAP(
    () => {
      changeMenuColorBlack(ref.current, "white");
    },
    ref.current,
    { scope: ref.current }
  );
  useEffect(() => {
    const removeLoader = () => {
      setLoading(false);
      ScrollTrigger.refresh();
      if (!mobile)
        document.body.style.backgroundColor = "rgba(255, 246, 30, 1)";
    };

    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener("load", removeLoader);
      return () => {
        window.removeEventListener("load", removeLoader);
      };
    }
  }, [mobile]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 2000);
    }
  }, [loading]);
  useEffect(() => {
    if (!isLoading) {
      lenis.stop();
      setTimeout(() => {
        lenis.start();
      }, 4000);
      setIsPreLoader(true);
    }
  }, [lenis, reactRouterHistory]);

  useEffect(() => {
    const fetchHandler = () => {
      if (statusHome === "succeeded") {
        setLoadT(true);
        const result = dataHome.data;
        const { attributes } = result;
        setDataFetch(attributes);
      }
    };
    fetchHandler();
  }, [statusHome, dataHome]);

  return (
    <div
      style={{
        margin: "auto",
      }}
    >
      <>
        {isLoading && <Header data={footer} />}

        <motion.div>
          {!isLoading && <LoadingPage />}
          <MainLandSection
            data={dataFetch}
            isPreLoader={!isLoading}
            loading={loading}
          />

          {isLoading && (
            <Approccio
              loading={loading}
              isLine={true}
              data={dataFetch}
              purpalText={locate === "it" ? "approccio" : "innovative"}
              leftSideText={
                <>
                  <div
                    className="hidden"
                    style={{
                      display: "inline-block",
                      position: "relative",

                      transform: "translateY(-13%)",
                    }}
                  >
                    <span className="slide-sss">
                      {" "}
                      {locate === "it" ? "innovativo per" : "approach that"}
                    </span>
                  </div>
                  <div
                    className="hidden"
                    style={{
                      display: "inline-block",
                      position: "relative",
                      zIndex: 3,
                      transform: "translateY(-23%)",
                    }}
                  >
                    <span className="slide-sss">
                      {" "}
                      {locate === "it" ? "portare vero" : "brings true"}
                    </span>
                  </div>
                  <div
                    className="hidden"
                    style={{
                      display: "inline-block",
                      position: "relative",
                      zIndex: 4,
                      transform: "translateY(-37%)",
                    }}
                  >
                    <span className="slide-sss">
                      {" "}
                      {locate === "it" ? "valore" : "value"}
                    </span>
                    <motion.span
                      onClick={() =>
                        nav(
                          locate === "en"
                            ? `/en` + dataFetch.Nav.Filosofia
                            : dataFetch.Nav.Filosofia
                        )
                      }
                      initial={{ scale: 0 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                      className="btn-wrapper"
                    >
                      <CrlBtn />
                    </motion.span>
                  </div>
                </>
              }
            />
          )}
          {isLoading && <ProjectSection loading={loading} />}
          {isLoading && <EsperienzaSection loading={loading} />}
          {isLoading && <StratogySection loading={loading} />}
          {isLoading && (
            <div ref={ref}>
              <BlogSection loading={loading} />

              <div
                onClick={() =>
                  nav(
                    locate === "en"
                      ? `${locate}` + dataFetch.Nav.Blog
                      : dataFetch.Nav.Blog
                  )
                }
                className="blog__btn-wrapper"
              >
                <div className="blog__text-svg-wrapper">
                  <div className="blog__svg-btn">
                    <svg
                      width="605"
                      height="66"
                      viewBox="0 0 605 66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="path"
                        d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1064C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3603C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3603C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5259 59.7513 11.2251C47.5419 9.99207 32.0867 8.36975 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
                        stroke="#5DF814"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <span>
                    {locate === "it"
                      ? "Guarda gli altri articoli"
                      : "Read the other articles"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </motion.div>
        {isLoading && <ContactSection loading={loading} />}
        {isLoading && <FooterSection loading={loading} data={footer} />}
      </>
      <Transition load={loadT} />
    </div>
  );
};

export default Home;
