// src/features/contact/contactSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const contactAction = createAsyncThunk(
  "contact/fetchContact",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    statusContact: "",
    dataContact: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactAction.pending, (state) => {
        state.statusContact = "loading";
      })
      .addCase(contactAction.fulfilled, (state, action) => {
        state.statusContact = "succeeded";
        state.dataContact = action.payload;
      })
      .addCase(contactAction.rejected, (state, action) => {
        state.statusContact = "failed";
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
