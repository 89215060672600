import { useEffect } from "react";
import WebFont from "webfontloader";
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "./State/Features/Home/homeSlice";
import { localeHandler } from "./State/Features/Ui/uiSlice";
import AppRouter from "./Compoents/Routes/Router";
import { projectAction } from "./State/Features/Project/projectSlice";

gsap.registerPlugin(ScrollTrigger);

WebFont.load({
  custom: {
    families: [
      "marco-bold",
      "marco-medium",
      "marco-light",
      "text-bold",
      "text-regular",
      "text-medium",
    ],
  },
});

const viewportWidth = window.innerWidth;
const duration = viewportWidth < 700 ? 10 : 1.5;
export const lenis = new Lenis({ duration, lerp: 0.1 });

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const locate = useSelector((state) => state.ui.locate);

  useEffect(() => {
    lenis.on("scroll", ScrollTrigger.update);
    function raf(time) {
      lenis.raf(time);

      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/" && locate === "en") {
      window.location.replace(locate);
    }
  }, [locate]);

  useEffect(() => {
    window.addEventListener("load", function () {
      if (/^\/en(\/.*)?$/.test(window.location.pathname)) {
        dispatch(localeHandler("en"));
      } else {
        dispatch(localeHandler("it"));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(homeAction(`home?locale=${locate}`));
  }, [dispatch, locate]);

  useEffect(() => {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }, []);

  useEffect(() => {
    if (location) {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 1500);
    }
  }, [location]);

  useEffect(() => {
    if (location) {
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 3000);
    }
  }, [location]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      dispatch(localeHandler(lang));
    } else {
      localStorage.setItem("lang", "it");
    }
  }, [dispatch]);

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("wheel", preventZoom);
    };
  }, []);
  useEffect(() => {
    dispatch(projectAction(`project?locale=${locate}`));
  }, [locate]);
  return (
    <div className="contents">
      <AppRouter />
    </div>
  );
}

export default App;
