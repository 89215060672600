import { useNavigate } from "react-router-dom";
import whiteLeftArrow from "../../assets/svgs/white-left-arrow.svg";
import { useSelector } from "react-redux";
import { ScrollTrigger } from "gsap/all";

function BlogItem({ cat_1, blog_title, img_src, link }) {
  const nav = useNavigate();
  const { locate } = useSelector((state) => state.ui);
  return (
    <section className="card-container" style={{ padding: 0 }}>
      <div className="card-holder" style={{ color: "white" }}>
        <div className="card-column">
          <div className="card-topper">
            <h6 style={{ color: "white" }}>
              {cat_1.map((e, index) => {
                return (
                  <span>
                    {e.Category}
                    {index !== cat_1.length - 1 && " / "}
                  </span>
                );
              })}
            </h6>
          </div>
          <div className="card-middler">
            <h3 class="title-blog">{blog_title}</h3>
          </div>
          <div onClick={() => nav(link)} className="direction-container">
            <span className="search">
              {locate === "it" ? "LEGGI L’ARTICOLO" : "READ THE ARTICLE"}
            </span>
            <img
              loading="eager"
              afterLoad={() =>
                setTimeout(() => {
                  ScrollTrigger.refresh();
                }, 1000)
              }
              src={whiteLeftArrow}
              alt=" This is a left white arrow"
              className="left-arrow slide"
            />
          </div>
        </div>
        <div onClick={() => nav(link)} className="card-img">
          <img src={img_src} alt="a picture of a girl typing" />
        </div>
      </div>
    </section>
  );
}

export default BlogItem;
