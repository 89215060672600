import { motion } from "framer-motion";
import smallStar from "../../../assets/svgs/small-star.svg";

const StratogyContent = ({ dataFetch }) => (
  <motion.div
    className="stratogy-left-side"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <motion.p className="stratogy-text">
      {dataFetch.body?.ProblemSolutionDescription}
    </motion.p>
    <motion.div className="stratogy-text-container">
      <motion.img className="stratogy-img" src={smallStar} alt="small star" />
      <p className="stratogy-short-text">
        {dataFetch.body?.OtherBeautifulThingsDescription}
      </p>
    </motion.div>
  </motion.div>
);

export default StratogyContent;
