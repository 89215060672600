import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function BlogCard({
  style,
  blogTitle,
  blogDescription,
  cat_1,
  src,
  link,
  fill,
  url,
}) {
  const nav = useNavigate();
  const { locate } = useSelector((state) => state.ui);

  return (
    <div className={`card-container ${style}`}>
      <div className="card-holder">
        <div className="card-column">
          <div className="card-topper">
            <p>
              {cat_1.map((e, index) => {
                return (
                  <span>
                    {e.Category}
                    {index !== cat_1.length - 1 && " / "}
                  </span>
                );
              })}
            </p>
          </div>
          <div className="card-middler">
            <p class="title-blog">{blogTitle}</p>
          </div>
          <div className="card-bottomer">
            <p>{blogDescription}</p>
          </div>
          <div
            onClick={() => nav(`${url}/${link}`)}
            className="direction-container card-btn"
          >
            <span className="search">
              {locate === "it" ? "Leggi l’articolo" : "Read the article"}
            </span>
            <svg
              className="left-arrow slide"
              width="29"
              height="24"
              viewBox="0 0 29 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.2703 10.485C28.2731 10.485 28.2758 10.485 28.2786 10.485V10.4851H28.2786V13.0954C21.0959 13.0954 14.9548 17.6184 12.5316 24L10.0913 23.0734C11.6939 18.8528 14.7101 15.3307 18.56 13.0954L0 13.0954L2.28201e-07 10.485L18.4009 10.485C14.637 8.32934 11.6823 4.97729 10.0972 0.957546L12.5256 0C14.9351 6.11057 21.0614 10.4817 28.2703 10.485Z"
                fill={fill}
              />
            </svg>
          </div>
        </div>
        <div className="card-img">
          <img src={src} alt="a picture of a girl typing" />
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
