import { Link } from "react-router-dom";
import Notes from "../Compoents/Home/EsperienzaSection/Notes";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { useRef } from "react";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import { useSelector } from "react-redux";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";

function NotFound() {
  const ref = useRef(null);

  const { footer, locate } = useSelector((state) => state.ui);

  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );
  return (
    <>
      <Header data={footer} />
      <section ref={ref} className="not-found">
        <div className="node-sticky">
          <p className="status-code">404</p>
          <Notes>take it easy</Notes>
          <Notes>{locate === "it" ? "torna indietro" : "go back"}</Notes>
          <Notes>{locate === "it" ? "non preoccuparti" : `don’t worry`}</Notes>
        </div>
        <span className="g">
          {locate === "it"
            ? "La pagina non esiste!"
            : "The page you’re looking for isn’t here"}
        </span>
        <Link to={locate==="en"?`/en`:"/"}>
          {locate === "it" ? "Torna alla home" : "Homepage"}
          <div className="home-link-svg">
            <svg
              viewBox="0 0 605 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="path"
                d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1064C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3603C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3603C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5259 59.7513 11.2251C47.5419 9.99207 32.0867 8.36975 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
                stroke="#5DF814"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </Link>
      </section>
      <FooterSection data={footer} />
      <Transition />
    </>
  );
}

export default NotFound;
