import CrlBtn from "../../GeneralComponents/ClrBtn";
import ftk from "../../../assets/svgs/ftk.svg";
import guy from "../../../assets/images/bird.gif";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Markdown from "react-markdown";

import { changeMenuColorBlack, slideUpHnadler } from "../../MotionTools/Slide";
import rehypeRaw from "rehype-raw";
import gsap from "gsap/all";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { isFormHanler } from "../../../State/Features/Ui/uiSlice";
import { useGSAP } from "@gsap/react";
import FooterSection from "../../FooterSection";
const Portfolio = () => {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const { dataFolosify, statusFolosify } = useSelector(
    (state) => state.folosify
  );

  const ref = useRef(null);
  const refSlide = useRef(null);
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ maxWidth: 700 });
  const nav = useNavigate();

  useEffect(() => {
    const dataHandler = () => {
      if (
        dataFolosify &&
        dataFolosify.data &&
        dataFolosify.data.attributes &&
        dataFolosify.data.attributes.body
      ) {
        // dispatch(apiReadyHandler(true));
        const body = dataFolosify.data.attributes.body.filter(
          (e) => e.__component === "filosofy-page.portfolio"
        );
        const seo = dataFolosify.data.attributes.SEO;

        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };
    dataHandler();
  }, [dataFolosify]);

  useGSAP(
    () => {
      const imgs = gsap.utils.toArray(".spacer-portfolio");

      gsap.fromTo(
        ".portfolio__git-wrapper",
        {
          clipPath: "inset(0% 0% 100% 0%)",
        },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
          ease: "none",

          scrollTrigger: {
            trigger: ref.current,
            start: "top 50%",

            once: true,
          },
        }
      );
      gsap.fromTo(
        ".portfolio__fkt-wrapper",
        {
          clipPath: "inset(100% 0% 0% 0%)",
        },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
          ease: "none",

          scrollTrigger: {
            trigger: ref.current,
            start: "top 50%",

            once: true,
          },
        }
      );

      gsap.set(".slide-s", { yPercent: 100 });
      slideUpHnadler(".slide-up", 1);
      slideUpHnadler(".btn-text-wrapper", 1);
      const slide = gsap.utils.toArray(".slide-ss");
      slide.forEach((e, index) => {
        gsap.fromTo(
          e,
          { yPercent: 100, display: "inline-block" },
          {
            yPercent: 0,
            delay: index === 0 ? 0 : index * 0.1,
            duration: 1,

            scrollTrigger: {
              trigger: refSlide.current,
              start: mobile ? "top 100%" : "top 60%",
            },
          }
        );
      });
      changeMenuColorBlack(ref.current, "white");
    },
    refSlide.current,
    mobile,
    { scope: ref.current }
  );
  const { locate, footer } = useSelector((state) => state.ui);

  if (statusFolosify === "loading") {
    return <div>Loading...</div>;
  }

  if (statusFolosify === "failed") {
    return <div>Error: {dataFolosify}</div>;
  }

  return (
    <>
      {dataFetch.body && (
        <>
          <section ref={ref} className="portfolio">
            <div className="portfolio__first-block">
              <div className="portfolio__fkt-wrapper">
                <img src={ftk} alt="Fotonika logo" />
              </div>
              <div className="portfolio__git-wrapper">
                <img className="guy" src={guy} alt="a bird looking suprised" />
              </div>
            </div>

            <div className="portfolio__second-block">
              <div className="portolio__paragraph-wrapper">
                <Markdown rehypePlugins={rehypeRaw} className="slide-up">
                  {dataFetch.body.Innovative_Value_Driven_Marketing}
                </Markdown>
              </div>
              <div className="portfolio__right-container">
                <div ref={refSlide} className="slide-wrapper">
                  <div className="hidden">
                    <span className="slide-ss">
                      {locate === "it"
                        ? "VUOI AVERE UN ASSAGGIO"
                        : "WOULD YOU LIKE A TASTE"}
                    </span>

                    <span className="slide-ss">
                      {" "}
                      {locate === "it"
                        ? "DEI NOSTRI LAVORI?"
                        : "OF THE KIND OF WORK WE DO?"}
                    </span>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <div
                    onClick={() => {
                      nav(
                        locate === "en"
                          ? `/${locate}` + "/contatti"
                          : "/contatti"
                      );
                      dispatch(isFormHanler(true));
                    }}
                    className="btn-text-wrapper"
                  >
                    <span>
                      {locate === "it"
                        ? "Chiedici il portfolio"
                        : "Ask for our portfolio!"}
                    </span>
                  </div>
                  <motion.div
                    onClick={() => {
                      nav(
                        locate === "en"
                          ? `/${locate}` + "/contatti"
                          : "/contatti"
                      );
                      dispatch(isFormHanler(true));
                    }}
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    transition={{ duration: 1 }}
                    className="btn-container"
                  >
                    <CrlBtn />
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
          <FooterSection data={footer} />
        </>
      )}
    </>
  );
};

export default Portfolio;
