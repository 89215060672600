import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import {
  changeMenuColorBlack,
  sildeHnadler,
} from "../Compoents/MotionTools/Slide";
import { useNavigate, useParams } from "react-router-dom";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Meta from "../Compoents/Meta/Meta";
import { blogDetailAction } from "../State/Features/Blog/blogDetailSlice";
import { blogAction } from "../State/Features/Blog/blogSlice";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";

function BlogDetail() {
  const [dataFetch, setDataFetch] = useState({ body: {}, seo: {} });
  const [share, setShare] = useState(false);
  const [blog, setDBlog] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const { dataBlogDetail, statusBlogDetail } = useSelector(
    (state) => state.blogDetail
  );
  const { dataBlog } = useSelector((state) => state.blog);
  const { locate, footer, url } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const param = useParams();
  const nav = useNavigate();
  const currentUrl = encodeURIComponent(window.location.href);
  const currentTitle = encodeURIComponent(document.title);
  const currentImage = encodeURIComponent(
    document.querySelector('meta[property="og:image"]')?.getAttribute("content")
  );

  useEffect(() => {
    dispatch(blogDetailAction(`blog-details?locale=${locate}`));
  }, []);

  useEffect(() => {
    dispatch(blogAction(`blog?locale=${locate}`));
  }, []);

  useEffect(() => {
    if (dataFetch.body && dataBlog.data) {
      const cat = dataBlog?.data.attributes.Blogs.filter(
        (e) => e.Slug === param.slug
      );
      setDBlog(cat[0]);
      const videos = document.querySelectorAll("video");

      // Loop through each video and set the 'playsinline' attribute
      videos.forEach((video) => {
        video.setAttribute("playsinline", "");
        video.playsInline = true;
      });
    }
  }, [dataBlog.data, dataFetch.body]);

  // Empty dependency array to ensure this runs only once after component mount

  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (statusBlogDetail === "succeeded") {
        const result = dataBlogDetail.data;
        try {
          const singleProject = result.filter(
            (e) => e?.attributes.Slug === param.slug
          );

          const body = singleProject[0]?.attributes.body.filter(
            (e) => e.__component === "blog-detail.body"
          );

          const seo = singleProject[0]?.attributes.SEO;
          setDataFetch({ body: body[0], seo: seo });
          setLoading(false); // Set loading to false after data is fetched
        } catch (err) {
          nav("/notfound");
        }
      }
    };
    dataHandler();
  }, [dataBlogDetail, statusBlogDetail]);

  useGSAP(
    () => {
      sildeHnadler("", ".slide-blog-detail");
    },
    ref.current,
    { scope: ref.current }
  );
  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );

  function transformDate(dateStr) {
    // Split the date string by '/'
    let parts = dateStr?.split("-");

    // Rearrange parts to dd/mm/yyyy
    let formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

    return formattedDate;
  }

  return (
    <>
      <Header data={footer} />
      {!loading && dataFetch.body && blog && (
        <>
          <main ref={ref} className="blog-detail">
            <Meta data={dataFetch.seo} />
            <div className="card-topper">
              <span className="blog_date">
                {transformDate(dataFetch.body.Blog_date)}
              </span>
              <p style={{ color: "white" }}>
                {blog.Category.map((e, index) => (
                  <span>
                    {e.Category}
                    {index !== blog.Category.length - 1 && " / "}
                  </span>
                ))}
              </p>
            </div>
            <div className="blog-detail__title-wrapper">
              <h1 className="slide-blog-detail">
                {dataFetch.body?.Blog_Title}
              </h1>
            </div>
            <div className="card-im">
              <img
                loading="eager"
                src={
                  process.env.REACT_APP_URL +
                  dataFetch.body.Blog_topic_image?.data.attributes.url
                }
                alt={
                  process.env.REACT_APP_URL +
                  dataFetch.body.Blog_topic_image?.data.attributes
                    .alterntiveText
                }
              />
            </div>
            <div className="markdowns-wrapper">
              <div className="blog__markdown-one-wrapper">
                <div className="blog_category-container">
                  <motion.div
                    layout
                    initial={{ transformOrigin: "left" }}
                    transition={{ duration: 0.3, ease: "backOut" }}
                    className={
                      share
                        ? "blog_category-item-a share-s-color"
                        : "blog_category-item-a"
                    }
                  >
                    <AnimatePresence mode="wait">
                      {share ? (
                        <motion.ul
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.1 }}
                        >
                          <li
                            onClick={() => setShare(false)}
                            style={{ marginRight: "15px" }}
                          >
                            <a style={{ pointerEvents: "none" }} href="">
                              <svg
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.72658 7.71275L17 0.439331L19.1213 2.56065L11.8479 9.83407L19.4532 17.4393L17.3318 19.5606L9.72658 11.9554L2.12132 19.5606L0 17.4393L7.60526 9.83407L0.331846 2.56065L2.45317 0.439331L9.72658 7.71275Z"
                                  fill="#6B22FF"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://api.whatsapp.com/send?text=${currentTitle}%20${currentUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                style={{ marginRight: "1rem" }}
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4576 2.90625C15.5871 1.03125 13.096 0 10.4487 0C4.98438 0 0.537946 4.44643 0.537946 9.91071C0.537946 11.6562 0.993304 13.3616 1.85938 14.8661L0.453125 20L5.70759 18.6205C7.15402 19.4107 8.78348 19.8259 10.4442 19.8259H10.4487C15.9085 19.8259 20.4531 15.3795 20.4531 9.91518C20.4531 7.26786 19.3281 4.78125 17.4576 2.90625ZM10.4487 18.1563C8.96652 18.1563 7.51562 17.7589 6.25223 17.0089L5.95312 16.8304L2.83705 17.6473L3.66741 14.6071L3.47098 14.2946C2.64509 12.9821 2.21205 11.4688 2.21205 9.91071C2.21205 5.37054 5.90848 1.67411 10.4531 1.67411C12.654 1.67411 14.721 2.53125 16.2746 4.08929C17.8281 5.64732 18.7835 7.71429 18.779 9.91518C18.779 14.4598 14.9888 18.1563 10.4487 18.1563ZM14.9665 11.9866C14.721 11.8616 13.5022 11.2634 13.2746 11.183C13.0469 11.0982 12.8817 11.058 12.7165 11.308C12.5513 11.558 12.0781 12.1116 11.9308 12.2813C11.7879 12.4464 11.6406 12.4688 11.3951 12.3438C9.93973 11.6161 8.98438 11.0446 8.02455 9.39732C7.77009 8.95982 8.27902 8.99107 8.75223 8.04464C8.83259 7.87946 8.79241 7.73661 8.72991 7.61161C8.66741 7.48661 8.17188 6.26786 7.96652 5.77232C7.76563 5.29018 7.56027 5.35714 7.40848 5.34821C7.26563 5.33929 7.10045 5.33929 6.93527 5.33929C6.77009 5.33929 6.50223 5.40179 6.27455 5.64732C6.04688 5.89732 5.40848 6.49554 5.40848 7.71429C5.40848 8.93304 6.29688 10.1116 6.41741 10.2768C6.54241 10.442 8.16295 12.942 10.6496 14.0179C12.221 14.6964 12.8371 14.7545 13.6228 14.6384C14.1004 14.567 15.0871 14.0402 15.2924 13.4598C15.4978 12.8795 15.4978 12.3839 15.4353 12.2813C15.3772 12.1696 15.2121 12.1071 14.9665 11.9866Z"
                                  fill="#6B22FF"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}&title=${currentTitle}&summary=&source=${currentImage}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.78296 6.62669H4.98086V20H0.78296V6.62669ZM2.88191 0C4.23124 0 5.3107 1.07946 5.3107 2.42879C5.3107 3.77811 4.17127 4.7976 2.88191 4.7976C1.53259 4.7976 0.453125 3.71814 0.453125 2.42879C0.453125 1.13943 1.53259 0 2.88191 0Z"
                                  fill="#6B22FF"
                                />
                                <path
                                  d="M7.55957 6.62668H11.5476V8.45577H11.6075C12.1473 7.3763 13.4966 6.29684 15.5356 6.29684C19.7335 6.29684 20.5431 9.05546 20.5431 12.6537V20.03H16.3452V13.5532C16.3452 11.994 16.3452 9.985 14.1863 9.985C12.0273 9.985 11.6975 11.6642 11.6975 13.4333V20.06H7.58956V6.68665L7.55957 6.62668Z"
                                  fill="#6B22FF"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                viewBox="0 0 11 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.63806 3.375H10.5131V0.125C10.1381 0.125 9.13806 0 7.88806 0C5.26306 0 3.38806 1.625 3.38806 4.75V7.5H0.513062V11H3.38806V20H6.88806V11H9.63806L10.2631 7.5H7.01306V5C7.01306 4 7.26306 3.375 8.63806 3.375Z"
                                  fill="#6B22FF"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://twitter.com/share?url=${currentUrl}&text=${currentTitle}&via=YourTwitterHandle&hashtags=YourHashtags`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_2513_1842)">
                                  <path
                                    d="M18.6541 17.9598L12.1052 8.44578L11.3648 7.36975L6.68015 0.564006L6.29203 0H0.535645L1.93957 2.04016L8.16832 11.0903L8.9087 12.1651L13.9133 19.4367L14.3016 20.0002H20.058L18.6541 17.96V17.9598ZM14.9857 18.6979L9.7863 11.1436L9.04592 10.0682L3.01247 1.30208H5.60775L10.487 8.39132L11.2274 9.46668L17.581 18.6978H14.9857V18.6979Z"
                                    fill="#6B22FF"
                                  />
                                  <path
                                    d="M9.04587 10.068L9.78625 11.1434L8.90849 12.1649L2.17382 20H0.513062L8.16811 11.0901L9.04587 10.068Z"
                                    fill="#6B22FF"
                                  />
                                  <path
                                    d="M19.3648 0L12.1051 8.44578L11.2273 9.46668L10.4869 8.39132L11.3647 7.36976L16.2823 1.64595L17.704 0H19.3648Z"
                                    fill="#6B22FF"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2513_1842">
                                    <rect
                                      width="19.5448"
                                      height="20"
                                      fill="white"
                                      transform="translate(0.513062)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </li>
                        </motion.ul>
                      ) : (
                        <motion.span
                          onClick={() => setShare(true)}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.4 }}
                        >
                          Share
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </motion.div>
                  <div
                    onClick={() => nav(locate ==="en"?`/${locate}`+url.Blog:url.Blog)}
                    className="blog_category-item-b"
                  >
                    <span>Tutte le news</span>
                  </div>
                </div>

                <ReactMarkdown
                  rehypePlugins={rehypeRaw}
                  className="blog__markdown-one"
                >
                  {dataFetch.body.Blog_article}
                </ReactMarkdown>
              </div>
            </div>
            <div
                onClick={() => nav(locate ==="en"?`/${locate}`+url.Blog:url.Blog)}
              className="blog__btn-wrapper blog-btn"
            >
              <div className="blog__text-svg-wrapper">
                <div className="blog__svg-btn">
                  <svg
                    width="605"
                    height="66"
                    viewBox="0 0 605 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="path"
                      d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1064C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3603C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3603C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5259 59.7513 11.2251C47.5419 9.99207 32.0867 8.36975 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
                      stroke="#5DF814"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <span>
                  {locate === "it"
                    ? "Guarda gli altri articoli"
                    : "Read the other articles"}
                </span>
              </div>
            </div>
          </main>
          <FooterSection data={footer} />
        </>
      )}

      <Transition />
    </>
  );
}

export default BlogDetail;
