import Notes from "./Notes"; // Assuming Notes is a simple component

const NotesList = ({ notes }) => {
  return (
    <div>
      {notes?.map((e, index) => (
        <Notes key={index}>{e.text}</Notes>
      ))}
    </div>
  );
};

export default NotesList;
