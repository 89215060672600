import { ScrollTrigger } from "gsap/ScrollTrigger";
import BigDreamSection from "../Compoents/Contact/BigDreamSection/BigDreamSection";
import Form from "../Compoents/Contact/FormSection/Form";
import Location from "../Compoents/Contact/Location/Location";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactAction } from "../State/Features/Contact/contactSlice";
import Header from "../Compoents/Header";

function Contact() {
  const { dataContact, statusContact } = useSelector((state) => state.contact);
  const { footer, locate } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const [dataFetch, setDataFetch] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    dispatch(contactAction(`contact?locale=${locate}`));
  }, [locate]);
  useEffect(() => {
    const fetchHandler = () => {
      if (statusContact === "succeeded") {
        const result = dataContact.data;
        const { attributes } = result;
        setDataFetch(attributes);
      }
    };
    fetchHandler();
  }, [statusContact, dataContact]);

  useEffect(() => {
    let timmer = setTimeout(() => {
      setLoad(true);
    }, 650);
    return () => {
      clearTimeout(timmer);
    };
  }, []);

  useEffect(() => {
    if (load) {
      ScrollTrigger.refresh();
    }
  }, [load]);

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function (event) {
      // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
      // you could also use addEventListener() instead
      window.onload = function () {
        // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
        window.requestAnimationFrame(function () {
          // GSAP custom code goes here
          setLoad(true);
        });
      };
    });
  });

  return (
    <div>
      <Header data={footer} />
      <BigDreamSection data={dataFetch} load={load} />
      {<Form data={dataFetch} load={load} />}
      {<Location data={dataFetch} />}

      <Transition load={load} />
    </div>
  );
}

export default Contact;
