import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import cursorPointer from "../../assets/svgs/left-arrow.svg";
import gsap from "gsap";
import { useSelector } from "react-redux";

const Cursor = () => {
  const cursorElementRef = useRef(null);
  const [starPosition, setStarPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false); // Track if the cursor is hovered
  const { locate } = useSelector((state) => state.ui);
  const setOpacityOne = () =>
    gsap.set(cursorElementRef.current, { opacity: 1 });
  const setOpacityZero = () =>
    gsap.set(cursorElementRef.current, { opacity: 1 });

  const setOpacityOne1 = () =>
    gsap.to(cursorElementRef.current, {
      opacity: 1,
      scale: 10,
      duration: 0.5,
      ease: "expo.out",
    });

  const setOpacityZero1 = () =>
    gsap.to(cursorElementRef.current, {
      opacity: 1,
      scale: 1,
      duration: 0.5,
      ease: "expo.out",
    });
  const projectContiner = document.querySelectorAll(".contain");
  useEffect(() => {
    const onMouseLeave = () => {
      setIsHovered(false); // Reset to original shape
    };

    const onMouseEnter = () => {
      setIsHovered(true); // Set the cursor to be hovered
    };

    // Add event listeners
    projectContiner.forEach((e) => {
      e.addEventListener("mouseenter", onMouseEnter);
      e.addEventListener("mouseleave", onMouseLeave);
    });

    // Clean up event listeners on component unmount
    return () => {
      projectContiner.forEach((e) => {
        e.removeEventListener("mouseenter", onMouseEnter);
        e.removeEventListener("mouseleave", onMouseLeave);
      });
    };
  }, [projectContiner]);

  // Mouse movement handler
  useEffect(() => {
    const onMouseMove = (e) => {
      setStarPosition({ x: e.clientX, y: e.clientY });
      setOpacityOne();
    };

    const onMouseLeave = () => {
      setOpacityZero();
    };

    const onMouseEnter = () => {
      setOpacityOne();
    };

    // List of events
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);

    // Clean up event listeners
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseenter", onMouseEnter);
      document.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [cursorElementRef]);

  return (
    <motion.div
      ref={cursorElementRef}
      animate={{
        left: isHovered ? starPosition.x - 90 : starPosition.x - 8,
        top: isHovered ? starPosition.y - 100 : starPosition.y - 6,
        width: isHovered ? "18rem" : "2rem",
        height: isHovered ? "18rem" : "2rem", // Slightly scale the cursor when hovered
      }}
      transition={{ ease: "linear", duration: isHovered ? 0.25 : 0.2 }}
      className="cursor"
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className="small-star"
        style={{
          transition: "all 0s ease", // Smooth transition for shape change
        }}
      >
        {/* Star shape path (from your SVG) */}
        <AnimatePresence mode="wait">
          <motion.path
            layout
            animate={{
              d: !isHovered
                ? "M9.97403 20L8.67532 14.8705L4.98701 18.6528L6.38961 13.5751L1.2987 14.9741L5.09091 11.2953L0 10L5.09091 8.65285L1.2987 4.97409L6.38961 6.37306L4.98701 1.34715L8.67532 5.07772L9.97403 0L11.3247 5.07772L15.013 1.34715L13.6104 6.37306L18.7013 4.97409L14.9091 8.65285L20 10L14.9091 11.2953L18.7013 14.9741L13.6104 13.5751L15.013 18.6528L11.3247 14.8705L9.97403 20Z" // Star shape path
                : "M 10 0 A 10 10 0 1 0 10 20 A 10 10 0 1 0 10 0", // Circle path
            }}
            fill="#6B22FF"
            transition={{
              duration: 0.1,
              ease: "easeInOut",
            }}
          />
        </AnimatePresence>
      </motion.svg>
      {isHovered && (
        <div className="text-content-container">
          <div>
            <span>{locate === "it" ? "VEDI" : "SEE"}</span>
          </div>

          <div className="img-cover">
            <img src={cursorPointer} alt="Arrow" />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Cursor;
