import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import gsap from "gsap";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import StickyContent from "./StickyContent"; // New file for StickyContent

const EsperienzaSection = ({ data }) => {
  const ref = useRef(null);
  const isMobileXSmall = useMediaQuery({ maxWidth: 400 });
  const [dataFetch, setDataFetch] = useState({ body: null });
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate } = useSelector((state) => state.ui);

  useEffect(() => {
    const dataHandler = () => {
      if (
        dataHome &&
        dataHome.data &&
        dataHome.data.attributes &&
        dataHome.data.attributes.body
      ) {
        const body = dataHome.data.attributes.body.filter(
          (e) => e.__component === "page.esperienza"
        );
        const seo =
          dataHome.attributes && dataHome.attributes.SEO
            ? dataHome.attributes.SEO
            : null;
        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
        setIsDataLoaded(true);
      }
    };
    dataHandler();
  }, [dataHome, statusHome]);

  if (statusHome === "loading") {
    return <div>Loading...</div>;
  }

  if (statusHome === "failed") {
    return <div>Error: {dataHome}</div>;
  }
  return (
    <div>
      <section ref={ref} className="section__container">
        <StickyContent
          data={dataFetch.body}
          refPass={ref}
          locate={locate}
          isDataLoaded={isDataLoaded}
        />
      </section>
    </div>
  );
};

export default EsperienzaSection;
