import { Helmet } from "react-helmet";

function Meta({ data }) {
  
  return (
    <Helmet>
      <title>{data && data.PageTitle}</title>
      <meta name="description" content={data && data.Description} />
      <meta name="keywords" content={data && data.Keywords} />
    </Helmet>
  );
}

export default Meta;
