
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const blogDetailAction = createAsyncThunk(
  "blogDetail/fetchBlogDetail",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const blogDetailSlice = createSlice({
  name: "blogDetail",
  initialState: {
    statusBlogDetail: "",
    dataBlogDetail: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogDetailAction.pending, (state) => {
        state.statusBlogDetail = "loading";
      })
      .addCase(blogDetailAction.fulfilled, (state, action) => {
        state.statusBlogDetail = "succeeded";
        state.dataBlogDetail = action.payload;
      })
      .addCase(blogDetailAction.rejected, (state, action) => {
        state.statusBlogDetail = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogDetailSlice.reducer;
