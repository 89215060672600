import { useMediaQuery } from "react-responsive";
import { Canvas } from "@react-three/fiber";
import { motion as m } from "framer-motion-3d";
import { Suspense, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGLTF } from "@react-three/drei";
import { ScrollTrigger } from "gsap/all";
import { useSelector } from "react-redux";
import { useGSAP } from "@gsap/react";

export const ModelMenu = ({ isPreLoader, click }) => {
  const [mashOne, setmashRefOne] = useState(null);
  const [mashTwo, setmashRefTwo] = useState(null);
  const [mashThree, setmashRefThree] = useState(null);
  const [mashOneP, setmashRefOneP] = useState(null);
  const [mashTwoP, setmashRefTwoP] = useState(null);
  const [mashThreeP, setmashRefThreeP] = useState(null);
  const mashRefOne = useRef();
  const mashRefTwo = useRef();
  const mashRefThree = useRef();
  const { nodes: f } = useGLTF("/models/F_lucido.glb");
  const { nodes: t } = useGLTF("/models/T_lucido.glb");
  const { nodes: k } = useGLTF("/models/K_lucido.glb");
  const mobile = useMediaQuery({ maxWidth: 1000 });
  const tl = useRef(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (mashRefOne.current && mashRefTwo.current && mashRefThree.current) {
        setmashRefOne(mashRefOne.current.rotation);
        setmashRefTwo(mashRefTwo.current.rotation);
        setmashRefThree(mashRefThree.current.rotation);
        setmashRefOneP(mashRefOne.current.position);
        setmashRefTwoP(mashRefTwo.current.position);
        setmashRefThreeP(mashRefThree.current.position);
      }
      ScrollTrigger.refresh();
    }, 1000);
    // Update state based on current ref values
    return () => {
      clearTimeout(timer);
    };
    // Keep running whenever mashRefOne, mashRefTwo, or mashRefThree changes
  }, [mashRefOne.current, mashRefTwo.current, mashRefThree.current, click]);

  const { position } = useSelector((state) => state.ui);

  useGSAP(
    () => {
      const animateModel = () => {
        tl.current = gsap.timeline();
        tl.current.fromTo(
          mashThree,
          { x: 0.4, y: -0.2, z: 0.5, position: "sticky", top: 0 },
          {
            scrollTrigger: {
              target: "body",
              start: `+=${position}`,
              end: "+=300%",
              scrub: 4,
            },
            x: Math.PI * 3,
          },
          0
        );
        tl.current.fromTo(
          mashOne,
          { x: 0, y: 0.5, z: 0.4, position: "sticky", top: 0 },
          {
            scrollTrigger: {
              target: "body",
              start: `+=${position}`,
              end: "+=300%",

              scrub: 2,
            },
            x: Math.PI * 3,
          },
          0
        );
        tl.current.fromTo(
          mashTwo,
          { x: -0.2, y: -0.25, z: -0.4, position: "sticky", top: "35%" },
          {
            scrollTrigger: {
              target: "body",
              start: `+=${position}`,
              end: "+=300%",

              scrub: 3.8,
            },
            x: Math.PI * 3,
          },
          0
        );
      };

      animateModel();
    },
    mashOne,
    mashThree,
    mashTwo,
    mashOneP,
    mashThreeP,
    mashTwoP,
    click,
    position,
    {}
  );

  const opacityModel = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, delay: isPreLoader ? 4 : 0 },
    },
  };

  return (
    <Canvas
      className="sence"
      flat
      linear
      style={{
        position: "relative",
      }}
    >
      <directionalLight
        position={[0, 40, 30]}
        intensity={0.6}
        color="#fff"
        shadow-mapSize={[1024, 1024]}
      >
        <orthographicCamera attach="shadow-camera" args={[-10, 10, 10, -10]} />
      </directionalLight>
      <ambientLight intensity={0.4} color="#fff" />

      <Suspense>
        <m.group>
          <m.mesh
            position={[mobile ? -2.9 : -3.2, 0.8, -0.8]}
            ref={mashRefOne}
            className="three"
            rotation={[0.1, 0.5, 0.4]}
            geometry={f.F.geometry}
            scale={mobile ? 0.03 : 0.035}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>
          <m.mesh
            position={[mobile ? -0.6 : -0.3, -0.6, 0]}
            ref={mashRefTwo}
            className="three"
            rotation={[0, -0.25, -0.4]}
            geometry={t.T.geometry}
            scale={mobile ? 0.027 : 0.032}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>

          <m.mesh
            position={[mobile ? 1.7 : 2.7, 0.4, 1]}
            ref={mashRefThree}
            rotation={[0.4, -0.2, 0.5]}
            geometry={k.K.geometry}
            scale={mobile ? 0.02 : 0.025}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>
        </m.group>
      </Suspense>
    </Canvas>
  );
};
