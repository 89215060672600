import { motion } from "framer-motion";
import { memo, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { changeMenuColorBlack, slideUpHnadler } from "../../MotionTools/Slide";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useGSAP } from "@gsap/react";

function Approccio({ leftSideText, purpalText }) {
  const { dataHome } = useSelector((state) => state.home);
  const { locate } = useSelector((state) => state.ui);
  const [dataFetch, setDataFetch] = useState({ body: null });
  let ref = useRef(null);
  const moble = useMediaQuery({ maxWidth: 700 });
  const [isLine, setIsLine] = useState(false);

  useGSAP(
    () => {
      const slide = gsap.utils.toArray();
      const tl = gsap.timeline();

      gsap.fromTo(
        ".slide-sss",
        { yPercent: 100, display: "inline-block" },
        {
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: "fastToSlow",
          immediateRender: true,
          onComplete: function () {
            setIsLine(true);
          },

          scrollTrigger: {
            trigger: ref.current,
            start: moble ? "top 100%" : "top 80%",
            once: true,
          },
        }
      );

      changeMenuColorBlack(ref.current, "black");

      slideUpHnadler(".secondary-text-container", 1);
    },
    { scope: ref.current }
  );
  useEffect(() => {
    const dataHandler = () => {
      if (
        dataHome &&
        dataHome.data &&
        dataHome.data.attributes &&
        dataHome.data.attributes.body
      ) {
        const body = dataHome.data.attributes.body.filter(
          (e) => e.__component === "page.folosify-menu"
        );
        const seo =
          dataHome.attributes && dataHome.attributes.SEO
            ? dataHome.attributes.SEO
            : null; // Check if attributes and SEO exist
        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };
    dataHandler();
  }, [dataHome]);

  return (
    <div>
      <section ref={ref} className="approccia-main">
        <motion.div className="primary-container">
          <motion.p className="primary-text">
            <div
              className="hidden"
              style={{
                display: "inline-block",
                position: "relative",
                zIndex: 1,
              }}
            >
              <span className="slide-sss">{locate === "it" ? "Un" : "An"}</span>
              <div className="inner-text-wrapper">
                {isLine && (
                  <span className="line-container slide-sss">
                    <motion.svg
                      width="267"
                      height="57"
                      viewBox="0 0 267 57"
                      fill="none"
                      xmlns="http://www.w3.org/2000/motion."
                    >
                      <motion.path
                        initial={{ pathLength: 0 }}
                        whileInView={{ pathLength: 1 }}
                        transition={{ duration: 2 }}
                        d="M266.253 14.6634C260.522 12.8455 254.838 11.6259 242.633 9.28184C195.114 0.155576 145.804 -1.56646 97.7147 3.47789C82.3557 5.08899 66.976 7.20606 51.9822 10.9226C41.8262 13.4399 31.5609 16.6106 22.6341 22.0881C18.3314 24.7282 12.0199 29.6301 13.7516 34.3805C16.1058 40.8382 24.6799 43.0736 31.1299 45.3928C55.7831 54.2574 88.0781 55.736 113.314 56.0412C145.68 56.4326 176.809 54.4406 208.57 48.0302C220.349 45.6529 232.355 43.2867 243.272 38.2498C256.296 32.2415 262.89 21.6086 244.963 14.6746C232.872 9.99839 219.696 8.5074 206.797 7.25214C166.979 3.37728 128.364 5.93455 88.6382 9.72637C70.9604 11.4137 53.3358 13.7294 35.7942 16.506C-2.65647 22.5922 -17.9605 33.1657 34.8329 42.9672C88.6382 52.9565 77.4543 51.7216 142.126 53.8814C193.863 55.6093 201.507 52.9565 209.276 52.0315"
                        stroke="#5DF814"
                        stroke-width="1.10993"
                        stroke-linecap="round"
                      />
                    </motion.svg>
                  </span>
                )}

                <span
                  style={{
                    display: "inline-block",
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="approccio slide-sss"
                >
                  {purpalText}
                </span>
              </div>
            </div>
            {leftSideText}
          </motion.p>
        </motion.div>
        <div className="secondary-text-container">
          <motion.p className="secondary-text">
            <div className="hiddens">
              <span className="slide-s"> {dataFetch?.body?.Mini_Text}</span>
            </div>
          </motion.p>
        </div>
      </section>
    </div>
  );
}

export default memo(Approccio);
