import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postHandler = createAsyncThunk("post/POST", async (data) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/elites`, data, {
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });
  } catch (err) {
    throw err;
  }
});
