function WordList({ index, word }) {
  return (
    <div>
      <ul className="word-list unorder-list">
        <li key={index} className="list-word">
          <div
            style={{ transform: `translateY(${-10 * index}px)` }}
            className="first-title"
          >
            <h3>{word}</h3>
          </div>
          <div
            style={{ transform: `translateY(${-10 * index}px)` }}
            className="second-title"
          >
            <h3 className="word">{word}</h3>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default WordList;
