import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { changeMenuColorBlack } from "../../MotionTools/Slide";

gsap.registerPlugin(ScrollTrigger);

const ScrollAnimation = ({ refpass }) => {
  const scrollTriggers = useRef([]);

  const endValue = (index, height) => height * 0.065 * (index + 1);

  const gsapAnimations = () => {
    const container = refpass.current;

    const word = gsap.utils.toArray(".word");
    const wordDiv = gsap.utils.toArray(".second-title");
    const listWord = gsap.utils.toArray(".list-word");
    const listDiv = gsap.utils.toArray(".unorder-list");

    changeMenuColorBlack(container, "black");

    // Kill existing ScrollTriggers for this section
    scrollTriggers.current.forEach((st) => st.kill());
    scrollTriggers.current = [];

    word.forEach((el, index) => {
      const start =
        index === 0 ? 50 : `+=${(container.offsetHeight * 0.1 * index) / 1.5}`;
      const st = gsap.fromTo(
        el,
        { xPercent: 100 },
        {
          xPercent: 0,
          scrollTrigger: {
            trigger: ".stratogy__container",
            start: () => `${start} top`,
            end: () => `+=${container.offsetHeight * 0.06 * (index + 1)}`,
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      );
      scrollTriggers.current.push(st.scrollTrigger);
    });

    wordDiv.forEach((el, index) => {
      const start =
        index === 0 ? 50 : `+=${(container.offsetHeight * 0.1 * index) / 1.5}`;
      const st = gsap.fromTo(
        el,
        { xPercent: -100 },
        {
          xPercent: 0,
          scrollTrigger: {
            trigger: ".stratogy__container",
            start: () => `${start} top`,
            end: () => `+=${container.offsetHeight * 0.06 * (index + 1)}`,
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      );
      scrollTriggers.current.push(st.scrollTrigger);
    });

    listWord.forEach((el, index) => {
      const start =
        index === 0 ? 0 : `+=${(container.offsetHeight * 0.09 * index) / 2}`;
      const xValue = 15 * 1.5 ** index;

      const st = gsap.fromTo(
        el,
        { xPercent: () => xValue },
        {
          xPercent: -10,
          scrollTrigger: {
            trigger: ".stratogy__container",
            start: () => `${start} top`,
            end: () => `+=${endValue(index, container.offsetHeight)}`,
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      );
      scrollTriggers.current.push(st.scrollTrigger);
    });

    listDiv.forEach((el, index) => {
      const st = gsap.fromTo(
        el,
        { y: 0 },
        {
          y: () => -container.offsetHeight * 0.01,
          scrollTrigger: {
            trigger: ".stratogy__container",
            start: () => `+=${container.offsetHeight * 0.01}`,
            end: () => `+=${container.offsetHeight}`,
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      );
      scrollTriggers.current.push(st.scrollTrigger);
    });
  };

  useEffect(() => {
    gsapAnimations();
    return () => {
      scrollTriggers.current.forEach((st) => st.kill());
    };
  }, [refpass.current]);

  return null;
};

export default ScrollAnimation;
