import smallWhiteStar from "../../../assets/svgs/small-white-star.svg";
import typingCat from "../../../assets/images/cat-typing.gif";
import { motion } from "framer-motion";
import { useRef, useEffect, useState, memo } from "react";
import CrlBtn from "../../GeneralComponents/ClrBtn";
import { useNavigate } from "react-router-dom";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import gsap from "gsap";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useGSAP } from "@gsap/react";

const ContactSection = ({ data, loading }) => {
  const nav = useNavigate();
  const ref = useRef(null);
  const [dataFetch, setDataFetch] = useState({ body: null });
  const { url } = useSelector((state) => state.ui);
  const moble = useMediaQuery({ maxWidth: 400 });
  useGSAP(
    () => {
      gsap.fromTo(
        ".q",
        { yPercent: 110 },
        {
          yPercent: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: ".contact",
            start: moble ? "top:80%" : "top center",
            once: 1,
          },
        }
      );

      gsap.fromTo(
        ".cat-img",
        { scaleY: 0, transformOrigin: "top" },
        {
          scaleY: 1,
          duration: 0.5,
          ease: "sine.inOut",
          scrollTrigger: { trigger: ".contact", start: "top center" },
        }
      );
    },

    {}
  );

  useGSAP(
    () => {
      setTimeout(() => {
        changeMenuColorBlack(ref.current, "white");
      }, 3000);
    },

    ref.current,
    { scope: ref.current }
  );

  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate } = useSelector((state) => state.ui);
  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (
        dataHome &&
        dataHome.data &&
        dataHome.data.attributes &&
        dataHome.data.attributes.body
      ) {
        const body = dataHome.data.attributes.body.filter(
          (e) => e.__component === "page.contatti"
        );
        const seo =
          dataHome.attributes && dataHome.attributes.SEO
            ? dataHome.attributes.SEO
            : null; // Check if attributes and SEO exist
        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };
    dataHandler();
  }, [dataHome, statusHome]);

  return (
    <div>
      {!loading && dataFetch.body && (
        <section ref={ref} id="black" className="contact section">
          <div className="contact__first-wrapper">
            <div className="question-container">
              <motion.p className="question">
                <div style={{ overflow: "hidden" }}>
                  <span className="q" style={{ display: "inline-block" }}>
                    {locate === "it" ? "Ti abbiamo" : "Did we rouse your"}
                  </span>
                </div>
                <div
                  style={{ overflow: "hidden", transform: "translateY(-2rem)" }}
                >
                  <span className="q" style={{ display: "inline-block" }}>
                    {locate === "it" ? "incuriosito?" : "interest?"}
                  </span>
                </div>
              </motion.p>
            </div>

            <div className="inner-container" onClick={() => nav(locate==="en"?`/en`+url.Contatti:url.Contatti)}>
              <motion.img
                initial={{ y: "100%" }}
                whileInView={{ y: "0%" }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                viewport={{ once: true }}
                className="small-white-star"
                src={smallWhiteStar}
                alt="small white star"
              />
              <p>{locate === "it" ? "Scrivici!" : "Get in touch!"}</p>
              <CrlBtn />
            </div>
          </div>
          <div className="contact__second-wrapper">
            {/* <div className="spacer-contact"></div> */}
            <img
              className="cat-img"
              src={typingCat}
              alt="a cat typing on a keyboard"
            />
            <div className="inner-container">
              <motion.p style={{ width: "100%" }}>
                <div
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <span
                    style={{ display: "inline-block" }}
                    className="slide-text q"
                  >
                    {locate === "it" ? "TI RISPONDIAMO" : "WE RESPOND"}
                  </span>
                </div>

                <span
                  style={{ display: "inline-block" }}
                  className="slide-text q"
                >
                  {locate === "it" ? "VELOCEMENTE ;)" : "QUICKLY ;)"}
                </span>
              </motion.p>
              <img
                className="flex-start q"
                src={smallWhiteStar}
                alt="small white star"
              />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default memo(ContactSection);
