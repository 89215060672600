function SoicalMedia({ customClass, data }) {
  return (
    <ul className={`social-media ${customClass}`}>
      <li>
        <a href={data.socialLinks?.instagram}>instagram</a>
      </li>
      <li>
        <a href={data.socialLinks?.facebook}>Facebook</a>
      </li>
      <li>
        <a href={data.socialLinks?.linkedin}>Linkedin</a>
      </li>
    </ul>
  );
}

export default SoicalMedia;
