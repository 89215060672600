import linkedin from "../../../assets/svgs/linkedin.svg";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import gsap from "gsap/all";
import { changeMenuColorBlack, slideUpHnadler } from "../../MotionTools/Slide";
import { useGSAP } from "@gsap/react";
import { useNavigate } from "react-router-dom";
const Team = () => {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const { dataFolosify, statusFolosify } = useSelector(
    (state) => state.folosify
  );
  const { locate } = useSelector((state) => state.ui);
  const ref = useRef(null);
  const nav = useNavigate();

  useEffect(() => {
    const dataHandler = () => {
      if (
        dataFolosify &&
        dataFolosify.data &&
        dataFolosify.data.attributes &&
        dataFolosify.data.attributes.body
      ) {
        const body = dataFolosify.data.attributes.body.filter(
          (e) => e.__component === "filosofy-page.team"
        );
        const seo = dataFolosify.data.attributes.SEO;

        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };
    dataHandler();
  }, [dataFolosify]);

  useGSAP(
    () => {
      const imgs = gsap.utils.toArray(".img-team");

      imgs.forEach((e) => {
        gsap.fromTo(
          e,
          { yPercent: -20 },
          {
            yPercent: 20,

            scrollTrigger: {
              trigger: e,
              start: "top bottom",
              end: () => `+=${ref.current?.offsetHeight}`,
              scrub: true,
            },
          }
        );
      });
      gsap.fromTo(
        ".team__img-container",
        { clipPath: "inset(0% 0% 100% 0%)" },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 5,
          stagger: 0.4,
          ease: "expo.out",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 60%",

            once: true,
          },
        }
      );

      gsap.fromTo(
        ".team-subtitle",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.2,
          delay: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 70%",

            once: true,
          },
        }
      );
      changeMenuColorBlack(ref.current, "white");
      slideUpHnadler(".team-title", 1);
    },
    ref.current,
    { scope: ref.current }
  );

  if (statusFolosify === "loading") {
    return <div>Loading...</div>;
  }

  if (statusFolosify === "failed") {
    return <div>Error: {dataFolosify}</div>;
  }

  return (
    <>
      {dataFetch.body && (
        <section ref={ref} className="team">
          <div className="team__title-container">
            <h3 className="team-title">Team</h3>
            <span className="team-subtitle">
              {locate === "it" ? "spaziale" : "of Wonders"}
            </span>
          </div>
          <div className="team-collection">
            {dataFetch.body.Team_Member.map((e, index) => {
              return (
                <div className="team__section-container">
                  <div className="team__img-container">
                    <img
                      loading="eager"
                      className="img-team"
                      src={
                        process.env.REACT_APP_URL +
                        e.Profile_Image.data.attributes.url
                      }
                      alt={
                        process.env.REACT_APP_URL +
                        e.Profile_Image.data.attributes.alternativeText
                      }
                    />
                  </div>
                  <div className="team__text-container">
                    <div>
                      <h4>{e.Name_Surname}</h4>
                      <p>{e.Profession}</p>
                    </div>

                    <div
                      onClick={() => window.open(e.Link, "_blank")}
                      className="team__profile-wrapper"
                    >
                      <img loading="eager" src={linkedin} alt="linkedin" />
                      <h4>{e.Linkedin_Profile}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default Team;
