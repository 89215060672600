import whiteStar from "../../../assets/svgs/small-white-star.svg";
import { motion } from "framer-motion";
function Notes({ children }) {
  return (
    <motion.div className="note">
      
      <span className="note__text">{children}</span>
      <img src={whiteStar} alt="white star" className="white-star" />
    </motion.div>
  );
}

export default Notes;
