import { motion } from "framer-motion";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import Marquee from "react-fast-marquee";
import Meta from "../../Meta/Meta";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import { useGSAP } from "@gsap/react";

// Define dreamOne animation with calculated duration

function BigDreamSection({ data }) {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const marqueeRef = useRef(null);
  const [showText, setShowText] = useState(false);
  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (data) {
        const body = data?.body.filter(
          (e) => e.__component === "contact-page.header"
        );
        setDataFetch({
          body: body[0],
          seo: data.SEO,
        });
      }
    };
    dataHandler();
  }, [data]);
  const ref = useRef(null).current;

  useGSAP(
    () => {
      changeMenuColorBlack(ref, "black");
    },
    ref,
    { scope: ref }
  );

  const searchWordHandler = (words) => {
    let count = 0;
    let indexOne;
    let indexTwo;
    let sum = "";
    let htmlContent = "";
    // Variable to accumulate HTML content
    for (let i = 0; i < words.length; i++) {
      if (words[i].includes("{")) {
        count++;
        indexOne = i;
      } else if (words[i].includes("}")) {
        count++;
        indexTwo = i;
      } else if (count < 1) {
        sum += words[i];
      } else if (count === 2) {
        // Accumulate non-bracket content
        htmlContent += `<span>${sum}</span>`;
        htmlContent += `<span class="big slide-s">${words.substring(
          indexOne + 1,
          indexTwo
        )}</span>`;
        // Add content inside brackets
        count = 0;
        sum = "";
      }
      if (i === words.length - 1) {
        htmlContent += `<span>${sum}</span>`;
      }
    }
    return htmlContent;
  };

  useLayoutEffect(() => {
    setShowText(true);
  }, []);

  return (
    <div>
      {dataFetch.body && (
        <section ref={ref} className="main">
          <Meta data={dataFetch.seo} />
          <motion.div className="main__dream-big-container">
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 2 }}
              ref={marqueeRef}
              className="marquee-container"
            >
              {showText && (
                <Marquee className="slide-m" speed={150} loop={0}>
                  <motion.p
                    dangerouslySetInnerHTML={{
                      __html: searchWordHandler(dataFetch.body?.marquee),
                    }}
                    className="dream-big-one"
                  ></motion.p>
                </Marquee>
              )}
            </motion.div>
          </motion.div>
          <div className="main__text-container">
            <p className="text">
              {dataFetch.body.Slide_Text.map((e,index) => {
                return (
                  <div key={index} className="hidden">
                    <motion.span
                      dangerouslySetInnerHTML={{
                        __html: searchWordHandler(e.line),
                      }}
                      initial={{ y: "100%" }}
                      animate={{ y: "0%" }}
                      transition={{ duration: 2 }}
                      className="slide-s"
                    ></motion.span>
                  </div>
                );
              })}
            </p>
          </div>
        </section>
      )}
    </div>
  );
}

export default BigDreamSection;
