import { motion } from "framer-motion";

const StickyParagraph = ({ data, paragraphRef }) => {
  return (
    <motion.p
      ref={paragraphRef}
      transition={{ duration: 1 }}
      style={{
        transition: "all 1s ease-out",
        paddingLeft: "100px",
        whiteSpace: "nowrap",
      }}
      className="section__container-paragraph"
    >
      {data?.text}
    </motion.p>
  );
};

export default StickyParagraph;
