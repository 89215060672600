import Lottie from "react-lottie-player";
import test from "../../assets/json/V2.json";
import ftkHeader from "../../assets/json/V2_SX.json";
import { useMediaQuery } from "react-responsive";

export const FTK = () => {
  return (
    <Lottie
      animationData={test}
      play
      loop={false}
      style={{ width: "67.4rem" }}
    ></Lottie>
  );
};

export const FTKHeader = () => {
  const mobile = useMediaQuery({ maxWidth: 700 });
  return (
    <Lottie
      animationData={ftkHeader}
      play
      loop={false}
      style={{
        transform: mobile
          ? "translate(-1.95rem, -1.8rem)"
          : "translate(-1.2rem, -9.5%)",
        width: mobile ? "42rem" : "24.4rem",
        color: "red",
      }}
    ></Lottie>
  );
};
