import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import gsap from "gsap";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import ContactSection from "../Compoents/Home/ContactSection/ContactSection";
import Meta from "../Compoents/Meta/Meta";
import { projectDetailAction } from "../State/Features/Project/projectDetailSlice";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";
import { useMediaQuery } from "react-responsive";

function ProjectDetail() {
  const mobile = useMediaQuery({ maxWidth: 700 });
  const [dataFetch, setDataFetch] = useState({
    intro: null,
    PD_body: null,
    seo: null,
  });
  const [contentLoaded, setContentLoaded] = useState(false); // New state variable

  const dispatch = useDispatch();
  const { statusProjectDetail, dataProjectDetail } = useSelector(
    (state) => state.projectDetail
  );
  const { locate, footer, url } = useSelector((state) => state.ui);
  const ref = useRef(null);
  const param = useParams();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(projectDetailAction(`projetti-details?locale=${locate}`));
  }, [locate, dispatch]);

  useEffect(() => {
    const fetchHandler = () => {
      if (statusProjectDetail === "succeeded") {
        const result = dataProjectDetail.data;
        try {
          const singleProject = result.filter(
            (e) => e.attributes?.Slug === param.slug
          );

          const intro = singleProject[0]?.attributes.body.filter(
            (e) => e.__component === "project-detail-page.intro"
          );
          const PD_body = singleProject[0]?.attributes.body.filter(
            (e) => e.__component === "project-detail-page.blog-body"
          );
          const seo = singleProject[0]?.attributes.SEO;
          setDataFetch({ intro: intro[0], PD_body: PD_body, seo });
        } catch (err) {}
      }
    };
    fetchHandler();
  }, [statusProjectDetail, dataProjectDetail, param.slug]);
  gsap.set(".slide", { yPercent: 150 });
  useGSAP(
    () => {
      const animationHandler = () => {
        gsap.fromTo(
          ".spacer",
          { yPercent: 100, transformOrigin: "bottom" },
          {
            yPercent: 0,
            duration: 1.5,
            delay: 1,
            ease: "none",
            stagger: 0.2,
          }
        );

        gsap.fromTo(
          ".slide",
          { yPercent: 150, overflow: "hidden" },
          {
            yPercent: 0,
            duration: 1.3,
            stagger: 0.05,
            ease: "expo.out",
            scrollTrigger: {
              trigger: ".project",
              start: "top 25%",
              once: true,
            },
          }
        );
        gsap.fromTo(
          ".project-title",
          { yPercent: 100 },
          {
            yPercent: 0,
            duration: 2,
            delay: 1,
            ease: "expo.out",
          }
        );
      };

      let timer = setTimeout(animationHandler, 700);
      return () => {
        clearTimeout(timer);
      };
    },
    { scope: ref.current }
  );

  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 1);
      setContentLoaded(true); // Set content loaded to true after the main content is rendered
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const videoRef = useRef(null); // Using useRef to store the video element

  useEffect(() => {
    const videoElement = videoRef.current; // Get the video element

    if (videoElement) {
      // Create an IntersectionObserver
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Start the video when it enters the viewport
              videoElement.play();
            } else {
              // Pause the video when it leaves the viewport
              videoElement.pause();
            }
          });
        },
        {
          threshold: 0.5, // Set to trigger when 50% of the video is in view
        }
      );

      // Start observing the video element
      observer.observe(videoElement);

      // Cleanup on component unmount
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      {dataFetch.intro && (
        <>
          <Header data={footer} />
          <main ref={ref} className="project-detail">
            <Meta data={dataFetch.seo} />
            <div className="detail-title__container">
              <h2 className="slide">{dataFetch.intro.Chimento}</h2>
            </div>
            <div className="detail-minititle__container">
              <div style={{ overflow: "hidden" }}>
                <span className="slide">
                  {locate === "it" ? "Settore" : "Industry"}
                </span>
              </div>
              <h3 className="slide">{dataFetch.intro.Settore}</h3>
            </div>
            <div className="detail-subtitle__container">
              <div style={{ overflow: "hidden" }}>
                <span className="slide">
                  {locate === "it" ? "Servizi" : "Services"}
                </span>
              </div>
              <h3 className="slide">{dataFetch.intro.Servizi}</h3>
            </div>
            <div className="detail-paragraph__container">
              <p className="slide">{dataFetch.intro.Servizi_paragraph}</p>
            </div>
            {dataFetch.PD_body[0].Images_And_Texts.map((e, index) => {
              const hasFirstImg = e.First_IMG.data?.attributes.url;
              const hasSecondImg = e.Second_IMG.data?.attributes.url;
              return (
                <div className={`detail-img__container `} key={index}>
                  {hasFirstImg || hasSecondImg ? (
                    <div
                      className={`detail-img__row ${
                        mobile && index === 0 ? "full" : ""
                      }`}
                    >
                      {hasFirstImg && (
                        <div className="block-hidden">
                          {e.First_IMG.data?.attributes.ext === ".mp4" ? (
                            <div className="center" style={{ width: "100%" }}>
                              <video
                                ref={videoRef}
                                loop
                                autoPlay
                                muted
                                playsInline
                                preload="metadata"
                              >
                                <source
                                  src={
                                    process.env.REACT_APP_URL +
                                    e.First_IMG.data?.attributes.url
                                  }
                                  type="video/mp4"
                                ></source>
                              </video>
                            </div>
                          ) : (
                            <div className="center" style={{ width: "100%" }}>
                              <img
                                loading="eager"
                                className="slide-img"
                                src={
                                  process.env.REACT_APP_URL +
                                  e.First_IMG.data?.attributes.url
                                }
                                threshold={400}
                                effect="blur"
                                alt="project image"
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {hasSecondImg && (
                        <div className="block-hidden">
                          {e.Second_IMG.data?.attributes.ext === ".mp4" ? (
                            <div className="center" style={{ width: "100%" }}>
                              <video
                                ref={videoRef}
                                loop
                                autoPlay
                                muted
                                playsInline
                                preload="metadata"
                              >
                                <source
                                  src={
                                    process.env.REACT_APP_URL +
                                    e.Second_IMG.data?.attributes.url
                                  }
                                  type="video/mp4"
                                ></source>
                              </video>
                            </div>
                          ) : (
                            <div className="center" style={{ width: "100%" }}>
                              {" "}
                              <img
                                loading="eager"
                                className="slide-img"
                                threshold={400}
                                src={
                                  process.env.REACT_APP_URL +
                                  e.Second_IMG.data?.attributes.url
                                }
                                effect="blur"
                                alt="project image"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {e.Left && (
                    <ReactMarkdown
                      rehypePlugins={rehypeRaw}
                      className="mark-down__pd-left"
                    >
                      {e.Left}
                    </ReactMarkdown>
                  )}
                  {e.Center && (
                    <ReactMarkdown
                      rehypePlugins={rehypeRaw}
                      className="mark-down__pd-center"
                    >
                      {e.Center}
                    </ReactMarkdown>
                  )}
                  {e.Right && (
                    <ReactMarkdown
                      rehypePlugins={rehypeRaw}
                      className="mark-down__pd-right"
                    >
                      {e.Right}
                    </ReactMarkdown>
                  )}
                </div>
              );
            })}
            <div
              onClick={() =>
                nav(
                  locate === "en" ? `/${locate}` + url.Progetti : url.Progetti
                )
              }
              className="blog__btn-wrapper blog-btn"
            >
              <div className="blog__text-svg-wrapper">
                <div className="blog__svg-btn">
                  <svg
                    width="605"
                    height="66"
                    viewBox="0 0 605 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="path"
                      d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1064C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3603C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3603C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5259 59.7513 11.2251C47.5419 9.99207 32.0867 8.36975 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
                      stroke="#5DF814"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <span>
                  {locate === "it"
                    ? "Guarda tutti i progetti"
                    : "See all the other projects"}
                </span>
              </div>
            </div>
          </main>
        </>
      )}
      {contentLoaded && (
        <>
          <ContactSection />
          <FooterSection data={footer} />
        </>
      )}
      <Transition />
    </>
  );
}

export default ProjectDetail;
