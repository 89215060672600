import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const policyAction = createAsyncThunk(
  "policy/fetchPolicy",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const PolicySlice = createSlice({
  name: "policy",
  initialState: {
    statusPolicy: "",
    dataPolicy: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(policyAction.pending, (state) => {
        state.statusPolicy = "loading";
      })
      .addCase(policyAction.fulfilled, (state, action) => {
        state.statusPolicy = "succeeded";
        state.dataPolicy = action.payload;
      })
      .addCase(policyAction.rejected, (state, action) => {
        state.statusPolicy = "failed";
        state.error = action.error.message;
      });
  },
});

export default PolicySlice.reducer;