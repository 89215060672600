import { Link, useNavigate } from "react-router-dom";
import SoicalMedia from "./GeneralComponents/SoicalMedia";
import { useEffect, useRef, useState } from "react";
import fotonikaLogo from "../assets/svgs/fotonika-logo.svg";
import { changeMenuColorBlack } from "./MotionTools/Slide";
import { useGSAP } from "@gsap/react";
import { useSelector } from "react-redux";
const FooterSection = ({ data }) => {
  const [dataFetch, setDataFetch] = useState({
    body: null,
    body_nav: null,
  });

  const { locate } = useSelector((state) => state.ui);

  useEffect(() => {
    if (data) {
      const footer = data.data?.attributes.Footer;
      const nav = data.data?.attributes.Nav;
      setDataFetch({ body: footer, body_nav: nav });
    }
  }, [data]);

  const ref = useRef(null);

  useGSAP(
    () => {
      changeMenuColorBlack(ref.current, "black");
    },
    ref.current,
    { scope: ref.current }
  );

  const nav = useNavigate();

  return (
    <div>
      {dataFetch.body && (
        <footer ref={ref} className="footer">
          <div className="footer__upper">
            <ul className="footer__menu-list">
              <li
                onClick={() =>
                  nav(
                    dataFetch.body && locate === "en"
                      ? `/en` + dataFetch.body.navigations.Progetti
                      : dataFetch.body.navigations.Progetti
                  )
                }
              >
                <div className="first-item">
                  <p>{dataFetch.body_nav.Projetti_Nav}</p>
                </div>
                <div className="second-item">
                  <Link
                    to={
                      dataFetch.body && locate === "en"
                        ? `/en` + dataFetch.body.navigations.Progetti
                        : dataFetch.body.navigations.Progetti
                    }
                  >
                    {dataFetch.body_nav.Projetti_Nav}
                  </Link>
                </div>
              </li>
              <li>
                <div className="first-item">
                  <p>{dataFetch.body_nav.Filosofia_Nav}</p>
                </div>
                <div className="second-item">
                  <Link
                    to={
                      dataFetch.body && locate === "en"
                        ? `/en` + dataFetch.body.navigations.Filosofia
                        : dataFetch.body.navigations.Filosofia
                    }
                  >
                    {dataFetch.body_nav.Filosofia_Nav}
                  </Link>
                </div>
              </li>
              <li
                onClick={() =>
                  nav(
                    dataFetch.body && locate === "en"
                      ? `/en` + dataFetch.body.navigations.Blog
                      : dataFetch.body.navigations.Blog
                  )
                }
              >
                <div className="first-item">
                  <p>{dataFetch.body_nav.Blog_Nav}</p>
                </div>
                <div className="second-item">
                  <Link
                    to={
                      dataFetch.body && locate === "en"
                        ? `/en` + dataFetch.body.navigations.Blog
                        : dataFetch.body.navigations.Blog
                    }
                  >
                    {dataFetch.body_nav.Blog_Nav}
                  </Link>
                </div>
              </li>
              <li
                onClick={() =>
                  nav(
                    dataFetch.body && locate === "en"
                      ? `/en` + dataFetch.body.navigations.Contatti
                      : dataFetch.body.navigations.Contatti
                  )
                }
              >
                <div className="first-item">
                  <p>{dataFetch.body_nav.Contatti_Nav}</p>
                </div>

                <div className="second-item">
                  <Link
                    to={
                      dataFetch.body && locate === "en"
                        ? `/en` + dataFetch.body.navigations.Contatti
                        : dataFetch.body.navigations.Contatti
                    }
                  >
                    {dataFetch.body_nav.Contatti_Nav}
                  </Link>
                </div>
              </li>
            </ul>
            <div className="upper-left-container">
              <SoicalMedia data={dataFetch.body} />
              <div className="policy">
                <Link
                  to={
                    locate === "en"
                      ? `/en` + dataFetch.body.privacyPolicyLink
                      : dataFetch.body.privacyPolicyLink
                  }
                >
                  Privacy policy
                </Link>
                <Link
                  to={
                    locate === "en"
                      ? `/en` + dataFetch.body.cookiesPolicyLink
                      : dataFetch.body.cookiesPolicyLink
                  }
                >
                  Cookie policy
                </Link>
                <div className="company-number">
                  <h3>fotonika</h3>
                  <p>p.iva 04468940277</p>
                </div>
              </div>
            </div>
          </div>

          <div className="fotonika-logo-container">
            <img src={fotonikaLogo} alt="This is Fotonika Logo" />
          </div>
        </footer>
      )}
    </div>
  );
};

export default FooterSection;
