import { motion } from "framer-motion";
import { ScrollTrigger } from "gsap/all";
import { useSelector } from "react-redux";
import ftk from "../../assets/svgs/pageLoadFTK.svg";

const Transition = () => {
  const isMenu = useSelector((state) => state.isTransitionMenu);

  if (!isMenu) {
    return (
      <>
        <motion.div
          className="slide-in"
          id="erase"
          initial={{ height: "0vh" }}
          animate={{ height: "0vh" }}
          exit={{ height: isMenu ? "0vh" : "100vh" }}
          onAnimationEnd={() => ScrollTrigger.refresh()}
          transition={{
            duration: 1.7,
            ease: [0.76, 0, 0.24, 1],
          }}
        >
          <motion.img
            className="fo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 1.7, ease: [0.76, 0, 0.24, 1] }}
            src={ftk}
          ></motion.img>
        </motion.div>
        <motion.div
          initial={{ height: "100vh" }}
          animate={{ height: "0vh" }}
          exit={{ height: "0vh" }}
          onAnimationEnd={() => ScrollTrigger.refresh()}
          transition={{
            duration: 1.7,
            ease: [0.76, 0, 0.24, 1],
          }}
          className="slide-out"
          id="erase"
        >
          <motion.img
            className="fo"
            initial={{ opacity: 1, display: "inline-block" }}
            animate={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.7, ease: [0.76, 0, 0.24, 1] }}
            src={ftk}
          ></motion.img>
        </motion.div>
      </>
    );
  } else {
    return null;
  }
};

export default Transition;

