import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../../Pages/Home";
import { AnimatePresence } from "framer-motion";
import Contact from "../../Pages/Contact";
import Cursor from "../GeneralComponents/Cursor";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Filosofy from "../../Pages/Filosofy";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../Pages/NotFound";
import ThankYou from "../../Pages/ThankYou";
import Project from "../../Pages/Project";
import gsap from "gsap/all";
import ProjectDetail from "../../Pages/projectDetail";
import Blog from "../../Pages/Blog";
import BlogDetail from "../../Pages/BlogDetail";
import { homeAction } from "../../State/Features/Home/homeSlice";
import { footerHandler, setUrl } from "../../State/Features/Ui/uiSlice";
import Policy from "../../Pages/Policy";
import Privacy from "../../Pages/Privacy";
import { useMediaQuery } from "react-responsive";

const AppRouter = () => {
  const location = useLocation();
  const [URL, setURL] = useState({ url: null });
  const dispatch = useDispatch();
  const { dataHome } = useSelector((state) => state.home);
  const { locate } = useSelector((state) => state.ui);
  const [isRoute, setIsRoute] = useState(false);
  const mobile = useMediaQuery({ maxWidth: 1000 });

  const timeoutRef = useRef(null);
  const DEBOUNCE_DELAY = 1000;

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      dispatch(homeAction(`home?locale=${locate}`));
    }, DEBOUNCE_DELAY);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [locate, dispatch]);

  useEffect(() => {
    if (dataHome) {
      const nav = dataHome?.data?.attributes?.Nav;
      setURL({ url: nav });
      dispatch(setUrl(nav));

      dispatch(footerHandler(dataHome));
    }
  }, [dataHome, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setIsRoute(true);
    }, 500);
  }, []);

  useLayoutEffect(() => {
    if (location) {
      gsap.to(".fo", { visibility: "visible" });

      setTimeout(() => gsap.to(".fo", { visibility: "hidden" }), 3500);
    }
  }, [location]);

  return (
    <div>
      {!mobile && <Cursor />}
      <AnimatePresence mode={"wait"}>
        <Routes location={location} key={location.pathname}>
          {/* Home route */}
          <Route
            index
            path={locate === "en" ? "/en" : "/"}
            element={<Home />}
          />

          {/* Conditional routes based on locate === 'en' */}
          {URL.url && (
            <Route
              path={
                locate === "en" ? "/en" + URL.url.Contatti : URL.url.Contatti
              }
              element={<Contact />}
            />
          )}
          {URL.url && (
            <Route
              path={
                locate === "en" ? "/en" + URL.url.Progetti : URL.url.Progetti
              }
              element={<Project />}
            />
          )}
          {URL.url && (
            <Route
              path={locate === "en" ? "/en" + URL.url.Blog : URL.url.Blog}
              element={<Blog />}
            />
          )}
          {URL.url && (
            <Route
              path={
                locate === "en"
                  ? "/en" + URL.url.Blog + "/:slug"
                  : URL.url.Blog + "/:slug"
              }
              element={<BlogDetail />}
            />
          )}
          {URL.url && (
            <Route
              path={
                locate === "en"
                  ? "/en" + URL.url.Progetti + "/:slug"
                  : URL.url.Progetti + "/:slug"
              }
              element={<ProjectDetail />}
            />
          )}

          {/* Static paths regardless of locale */}
          <Route
            path={locate === "en" ? "/en/thankyou" : "/thankyou"}
            element={<ThankYou />}
          />
          <Route
            path={locate === "en" ? "/en/privacy" : "/privacy"}
            element={<Privacy />}
          />
          <Route
            path={locate === "en" ? "/en/policy" : "/policy"}
            element={<Policy />}
          />

          {/* Additional route for Filosofia */}
          {URL.url && (
            <Route
              path={
                locate === "en" ? "/en" + URL.url.Filosofia : URL.url.Filosofia
              }
              element={<Filosofy />}
            />
          )}

          {/* Handle not found fallback */}
          {isRoute && <Route path="*" element={<NotFound />} />}
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default AppRouter;
