import { useState } from "react";
function CrlBtn({ onClick }) {
  const [hover, sethover] = useState("#5DF814");
  return (
    <div
      onMouseLeave={() => sethover("#5DF814")}
      onMouseEnter={() => sethover("rgba(107, 34, 255, 1)")}
      onClick={onClick}
      className="crl-btn">
      <svg
        width="36"
        height="31"
        viewBox="0 0 36 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9693 0.798828C19.0025 8.49114 26.7164 13.9929 35.7924 13.9929L35.7924 17.2777C26.7539 17.2777 19.0261 22.9693 15.9768 30.9998L12.906 29.8338C14.9227 24.5227 18.7182 20.0906 23.5628 17.2777L0.207397 17.2777V13.9929L23.3626 13.9929C18.6262 11.2803 14.9081 7.06212 12.9135 2.00378L15.9693 0.798828Z"
          fill={hover}
        />
      </svg>
    </div>
  );
}

export default CrlBtn;
