import { useDispatch, useSelector } from "react-redux";
import IntroSection from "../Compoents/Filosofy/IntroSection";
import Portfolio from "../Compoents/Filosofy/Portfolio/Portfolio";
import Team from "../Compoents/Filosofy/Team/team";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { useEffect } from "react";
import { folosifyAction } from "../State/Features/Filosofy/filosofySlice";

function Filosofy() {
  const dispatch = useDispatch();
  const { locate} = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(folosifyAction(`filosofy?locale=${locate}`));
  }, [locate]);

  return (
    <div className="filosofy">
      <IntroSection />
      <Team />
      <Portfolio />
      {/* <FooterSection data={footer} /> */}
      <Transition />
    </div>
  );
}

export default Filosofy;
