// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./Features/Home/homeSlice";
import contactReducer from "./Features/Contact/contactSlice";
import projectReducer from "./Features/Project/projectSlice";
import projectDetailReducer from "./Features/Project/projectDetailSlice";
import blogReducer from "./Features/Blog/blogSlice";
import blogDetailReducer from "./Features/Blog/blogDetailSlice";
import folosifyReducer from "./Features/Filosofy/filosofySlice";
import uiReducer from "./Features/Ui/uiSlice";
import { postSlice } from "./api";
import privacyReducer from "./Features/Privacy/privacy";
import policyReducer from "./Features/Policy/policy";

const store = configureStore({
  reducer: {
    home: homeReducer,
    contact: contactReducer,
    project: projectReducer,
    projectDetail: projectDetailReducer,
    blog: blogReducer,
    blogDetail: blogDetailReducer,
    folosify: folosifyReducer,
    privacy:privacyReducer,
    policy:policyReducer,
    ui: uiReducer,
    postSlice: postSlice.reducer,
  },
});

export default store;
