import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../GeneralComponents/input";
import CrlBtn from "../../GeneralComponents/ClrBtn";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import gsap from "gsap";
import { object, string, number } from "yup";
import { useFormik } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "react-responsive";
import { postHandler } from "../../../State/api/thunk";
import { lenis } from "../../../App";
import { useGSAP } from "@gsap/react";
import { Link, useNavigate } from "react-router-dom";
import { isFormHanler, routeHandler } from "../../../State/Features/Ui/uiSlice";

function Form({ load }) {
  const [tick, setTick] = useState(false);
  const [tickfolio, setTickFolio] = useState(false);
  const { locate, isForm } = useSelector((state) => state.ui);
  const mobile = useMediaQuery({ maxWidth: 700 });

  const SchemaMap = object({
    name: string().required(
      locate === "it" ? "Il campo è richiesto" : "This field is required!"
    ),
    surname: string().required(
      locate === "it" ? "Il campo è richiesto" : "This field is required!"
    ),
    company: string().required(
      locate === "it" ? "Il campo è richiesto" : "This field is required!"
    ),
    city: string().required(
      locate === "it" ? "Il campo è richiesto" : "This field is required!"
    ),
    phone_number: number()
      .positive()
      .integer()
      .required(
        locate === "it"
          ? "Il campo è richiesto"
          : "Please enter your phone number!"
      ),
    email: string()
      .email()
      .required(
        locate === "it" ? "Il campo è richiesto" : "This field is required!"
      ),
    message: string().required(
      locate === "it" ? "Il campo è richiesto" : "This field is required!"
    ),
  });
  const dispatch = useDispatch();
  const ref = useRef(null);
  const nav = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      company: "",
      city: "",
      phone_number: "",
      email: "",
      message: "",
      portfolio: false,
    },
    validationSchema: SchemaMap,
    onSubmit: (value, { resetForm }) => {
      const data = {
        data: {
          name: value.name,
          surname: value.surname,
          company: value.company,
          city: value.city,
          phone_number: value.phone_number,
          email: value.email,
          message: value.message,
          portfolio: tickfolio,
        },
      };
      if (tick) {
        dispatch(postHandler(data));
        dispatch(routeHandler(true));
        emailjs
          .send(
            process.env.REACT_APP_ES,
            process.env.REACT_APP_ET,

            {
              name: value.name,
              email: value.email,
              surname: value.surname,
              compnay: value.company,
              city: value.city,
              phone_number: value.phone_number,
              message: value.message,
              portfolio: tickfolio,
            },

            {
              publicKey: process.env.REACT_APP_EP,
            }
          )
          .then(
            () => {},
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
        gsap.fromTo(
          ".animate",
          { yPercent: 0, background: "#fff61e" },
          { yPercent: -20, yoyoEase: true, repeat: 3, duration: 0.5 }
        );

        nav("/thankyou");
        formRef.current.reset();
      } else {
        gsap.fromTo(
          ".animate",
          { yPercent: 0, background: "red" },
          { yPercent: -20, yoyoEase: true, repeat: 3, duration: 0.5 }
        );
      }
    },
  });
  useEffect(() => {
    if (tick) {
      gsap.to(".animate", { yPercent: 0, background: "#fff61e" });
    } else {
      gsap.fromTo(
        ".animate",
        { yPercent: 0, background: "red" },
        { yPercent: -20, yoyoEase: true, repeat: 3, duration: 0.5 }
      );
    }
  }, [tick]);
  const formRef = useRef();

  useEffect(() => {
    if (isForm) {
      setTimeout(() => {
        lenis.scrollTo(".form", 100);
        dispatch(isFormHanler(false));
      }, 1500);
    }
  }, [isForm,dispatch]);

  useEffect(() => {
    if (mobile && load) {
      gsap.fromTo(
        ".form-title",
        { yPercent: 200 },
        {
          yPercent: 0,
          duration: 1,
          ease: "circ",
        }
      );
      gsap.to(".input-container", {
        opacity: 1,
        stagger: 0.1,
        duration: 1,
        ease: "circ",
      });
      return;
    }
    if (load) {
      gsap.fromTo(
        ".form-title",
        { yPercent: 200 },
        {
          yPercent: 0,
          duration: 1,
          ease: "circ",
          scrollTrigger: {
            trigger: ".form-container",
            start: "top top",
            once: true,
          },
        }
      );
      gsap.to(".input-container", {
        opacity: 1,
        stagger: 0.1,
        duration: 1,
        ease: "circ",
        scrollTrigger: {
          trigger: ".form-container",
          start: "top top",
          once: true,
        },
      });
    }
  }, [mobile, load,dispatch]);

  useGSAP(
    () => {
      changeMenuColorBlack(ref.current, "white");
    },
    ref.current,
    { scope: ref.current }
  );

  useEffect(() => {
    const textarea = document.getElementById("autoResizeTextarea");

    function autoResize() {
      textarea.style.height = "auto"; // Reset the height
      textarea.style.height = textarea.scrollHeight + "px"; // Set it to the scroll height
    }

    textarea?.addEventListener("input", autoResize);

    // Optionally, call autoResize on page load if the textarea might already have content
    window?.addEventListener("load", autoResize);
  }, []);

  return (
    <div>
      {
        <section ref={ref} className="form-container">
          <div className="form-title-container">
            <p className="form-title">
              {locate === "it"
                ? "Compila il form e cominciamo!"
                : "Fill in the form and let’s get started!"}
            </p>
          </div>

          <form ref={formRef} className="form">
            <Input
              name="name"
              onChange={formik.handleChange}
              label={locate === "it" ? "*Nome" : "*Name"}
              placeHolder={locate === "it" ? "Il tuo nome" : "Your name"}
              message={formik.errors.name}
              errorColor={formik.errors.name && "rgba(232, 20, 20, 1)"}
            />
            <Input
              name="surname"
              onChange={formik.handleChange}
              label={locate === "it" ? "*Cognome" : "*Surname"}
              placeHolder={locate === "it" ? "Il tuo cognome" : "Your surname"}
              message={formik.errors.surname}
              errorColor={formik.errors.surname && "rgba(232, 20, 20, 1)"}
            />
            <Input
              name="company"
              onChange={formik.handleChange}
              label={locate === "it" ? "*Azienda" : "*Company"}
              placeHolder={
                locate === "it" ? "La tua azienda" : "Your Company’s name"
              }
              message={formik.errors.company}
              errorColor={formik.errors.company && "rgba(232, 20, 20, 1)"}
            />
            <Input
              name="city"
              onChange={formik.handleChange}
              label={locate === "it" ? "*Città" : "*City"}
              placeHolder={
                locate === "it" ? "La tua città" : "Where your business is"
              }
              message={formik.errors.city}
              errorColor={formik.errors.city && "rgba(232, 20, 20, 1)"}
            />
            <Input
              name="phone_number"
              onChange={formik.handleChange}
              label={locate === "it" ? "*Telefono" : "*Phone"}
              isNum={true}
              placeHolder={locate === "it" ? "Il tuo numero" : "Your number"}
              message={formik.errors.phone_number}
              errorColor={formik.errors.phone_number && "rgba(232, 20, 20, 1)"}
            />
            <Input
              name="email"
              onChange={formik.handleChange}
              label={"*Email"}
              placeHolder={
                locate === "it" ? "La tua mail" : "Your email address"
              }
              message={formik.errors.email}
              errorColor={formik.errors.email && "rgba(232, 20, 20, 1)"}
            />
            <div
              style={{
                background: formik.errors.message && "rgba(232, 20, 20, 1)",
              }}
              className="input-container"
            >
              <div className="center-input">
                <label className="input-label">
                  {locate === "it"
                    ? "*Di cosa hai bisogno?"
                    : "*How can we help?"}
                </label>
                <p>{formik.errors.message}</p>
              </div>
              <div className="input-wrapper">
                <textarea
                  id="autoResizeTextarea"
                  style={{
                    background: formik.errors.message && "rgba(232, 20, 20, 1)",
                  }}
                  name="message"
                  onChange={formik.handleChange}
                  className="input"
                  placeHolder={
                    locate === "it"
                      ? "Scrivi qui la tua richiesta"
                      : "Write your request here"
                  }
                  type={"text"}
                  autoComplete="off"
                />
              </div>
            </div>
          </form>
          <div className="bottom-container">
            <div className="privacy-container">
              <div
                onClick={() => setTick(!tick)}
                className="privacy-clr animate"
              >
                <AnimatePresence mode="wait">
                  {tick && (
                    <motion.svg
                      width="26"
                      height="18"
                      viewBox="0 0 26 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <motion.path
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        exit={{ pathLength: 0 }}
                        transition={{ duration: 0.3 }}
                        d="M3 7.5L10.6923 15L23 3"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="square"
                      />
                    </motion.svg>
                  )}
                </AnimatePresence>
              </div>
              <p>
                {locate === "it"
                  ? "*Ho letto e accetto le condizioni contenute nella"
                  : "*I have read and accept the conditions and the"}
                &nbsp;
                <Link to={locate ==="en"?`/en`+"/privacy":"/privacy"}> privacy policy.</Link>
                &nbsp;
              </p>
            </div>
            <div className="privacy-container">
              <div
                onClick={() => setTickFolio(!tickfolio)}
                className={
                  tickfolio ? "privacy-clr light" : "privacy-clr veild"
                }
              >
                <AnimatePresence mode="wait">
                  {tickfolio && (
                    <motion.svg
                      width="26"
                      height="18"
                      viewBox="0 0 26 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <motion.path
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        exit={{ pathLength: 0 }}
                        transition={{ duration: 0.3 }}
                        d="M3 7.5L10.6923 15L23 3"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="square"
                      />
                    </motion.svg>
                  )}
                </AnimatePresence>
              </div>
              <p>
                {locate === "it"
                  ? "*Mandami il portfolio"
                  : "*Send me the portfolio"}
              </p>
            </div>
            <div className="btn-container" onClick={formik.handleSubmit}>
              <CrlBtn />
              <span>Let’s go!</span>
            </div>
          </div>
        </section>
      }
    </div>
  );
}

export default Form;
