function Input({
  label,
  placeHolder,
  message,
  isNum,
  onChange,
  name,
  errorColor,
}) {
  return (
    <div style={{ background: errorColor }} className="input-container">
      <div className="center-input">
        <label className="input-label">{label}</label>
        <p>{message}</p>
      </div>
      <div className="input-wrapper">
        <input
          style={{ background: errorColor }}
          name={name}
          onChange={onChange}
          className="input"
          placeholder={placeHolder}
          type={isNum ? "number" : "text"}
          autoComplete="off"
        />
      </div>
    </div>
  );
}

export default Input;
