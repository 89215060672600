// BlogSection.js
import { useEffect, useState, useCallback, useRef } from "react";
import BlogCardList from "./BlogCardList";
import BlogDataFetcher from "./BlogDataFetcher";
import BlogGSAP from "./BlogGSAP";
import { useSelector } from "react-redux";

const BlogSection = () => {
  const [dataFetch, setDataFetch] = useState([]);
  const { url, locate } = useSelector((state) => state.ui);
  const { statusBlog } = useSelector((state) => state.blog);
  const { dataBlogDetail } = useSelector((state) => state.blogDetail);

  const blogRef = useRef(null);

  const getFirstThreeLines = useCallback((text, maxLineLength = 30) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    for (let word of words) {
      if ((currentLine + word).length <= maxLineLength) {
        currentLine += (currentLine.length ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
        if (lines.length === 3) {
          break;
        }
      }
    }

    if (lines.length < 3 && currentLine.length) {
      lines.push(currentLine);
      lines.push(",...");
    }

    return lines.join("\n").concat(" ...");
  }, []);

  const handleBlogDetail = useCallback(
    (slug) => {
      if (dataBlogDetail.data) {
        const description = dataBlogDetail.data.filter(
          (e) => e?.attributes.Slug === slug
        );
        if (description[0]?.attributes.body[0]) {
          const Blog_article = description[0]?.attributes.body[0];
          const text = Blog_article.Blog_article;

          return getFirstThreeLines(text);
        }
      }
      return "data is not ready";
    },
    [dataBlogDetail.data, getFirstThreeLines]
  );

  const colors = ["green", "purple", "yellow"];

  if (statusBlog === "loading") {
    return <div>Loading...</div>;
  }

  if (statusBlog === "failed") {
    return <div>Error: {}</div>;
  }

  return (
    <div>
      <BlogDataFetcher locate={locate} setDataFetch={setDataFetch} />
      {dataFetch.length > 0 && url?.Blog && (
        <div>
          <div ref={blogRef} className="blog">
            <div className="blog-holder">
              <h3>Blog</h3>
              <BlogGSAP blogRef={blogRef} />
              <BlogCardList
                dataFetch={dataFetch}
                handleBlogDetail={handleBlogDetail}
                colors={colors}
                url={url}
                locate={locate}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogSection;
