import whiteLeftArrow from "../../../assets/svgs/white-left-arrow.svg";
import SoicalMedia from "../../GeneralComponents/SoicalMedia";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import { useSelector } from "react-redux";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import FooterSection from "../../FooterSection";
function Location({ data }) {
  const ref = useRef(null);
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const [dataSocial, setDataSocial] = useState({});
  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate, footer } = useSelector((state) => state.ui);

  useEffect(() => {
    if (dataHome && statusHome === "succeeded") {
      const result = dataHome.data;
      const attributes = result.attributes;
      const { Footer } = attributes;
      const footer = Footer;
      setDataSocial(footer);
    }
  }, [dataHome, statusHome]);

  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (data) {
        const body = data?.body.filter(
          (e) => e.__component === "contact-page.address"
        );
        setDataFetch({
          body: body[0],
          seo: data.SEO,
        });
      }
    };
    dataHandler();
  }, [data]);

  useGSAP(
    () => {
      changeMenuColorBlack(ref.current, "white");
      gsap.to(
        ".left-arrow",

        {
          yPercent: 0,
          duration: 2,
          ease: "circ",
          onComplete: () => {
            if (document.querySelector(".left-arrow")) {
              document.querySelector(".left-arrow").style.transition =
                "transform .5s";
            }
          },
          scrollTrigger: {
            trigger: ref.current,
            start: "top 80%",
            once: true,
          },
        }
      );
      gsap.fromTo(
        ".slide",
        { yPercent: 200 },
        {
          yPercent: 0,
          duration: 2,
          ease: "circ",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 80%",
            once: true,
          },
        }
      );
      gsap.fromTo(
        ".move",
        { yPercent: 200, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 2,
          ease: "circ",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 80%",
            once: true,
          },
        }
      );

      gsap.fromTo(
        ".spacer",
        { scaleY: 1 },
        {
          scaleY: 0,

          duration: 2,
          ease: "circ",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 40%",
            once: true,
          },
        }
      );

      gsap.fromTo(
        ".visual-location",
        { rotate: "0deg" },
        {
          rotate: "10deg",

          duration: 2,
          ease: "circ",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 80%",
            once: true,
          },
        }
      );
    },
    ref.current,
    { scope: ref.current }
  );

  return (
    <div>
      {dataFetch.body && (
        <>
          <section ref={ref} className="location section-contact">
            <div className="left-container">
              <div className="hide">
                <h4 className="location-title slide ">
                  {locate === "it"
                    ? "La nostra sede fotonika!"
                    : "Our stunning office"}
                </h4>
              </div>
              <div className="hide">
                <p className="address slide">{dataFetch.body.location}</p>
              </div>
              <div className="hide">
                <p className="phone-number slide">
                  {dataFetch.body.phone_number}
                </p>
              </div>

              <div className="direction-container">
                <Link to={dataFetch.body.addressURL} className="search slide">
                  {locate === "it"
                    ? "TROVACI SU GOOGLE MAPS"
                    : "Find us on Google Maps"}
                </Link>
                <img
                  src={whiteLeftArrow}
                  alt=" This is a left white arrow"
                  className="left-arrow"
                />
              </div>
              <div className="move">
                <SoicalMedia data={dataSocial} customClass="color-white" />
              </div>
            </div>
            <div className="right-container">
              <img
                className="visual-location"
                src={
                  process.env.REACT_APP_URL +
                  dataFetch.body.visual_Place.data?.attributes.url
                }
                alt="this is a visual map of fotonika location"
              />
              <div style={{ width: "100%" }} className="location-map-container">
                <div className="spacer"></div>
                <img
                  className="location-map"
                  src={
                    process.env.REACT_APP_URL +
                    dataFetch.body.map.data?.attributes.url
                  }
                  alt="this is a map of fotonika location"
                />
              </div>
            </div>
          </section>
          <FooterSection data={footer} />
        </>
      )}
    </div>
  );
}

export default Location;
