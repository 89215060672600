import { createSlice } from "@reduxjs/toolkit";
import { postHandler } from "./thunk";

const postInitialState = {
  status: "",
  error: undefined,
};

export const postSlice = createSlice({
  name: "post",
  initialState: postInitialState,
  reducers: {},
  extraReducers(builder) {
    // Changed the thunk action types to their corresponding pending, fulfilled, and rejected action types
    builder.addCase(postHandler.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(postHandler.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(postHandler.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});
