import { memo, useEffect, useRef, useState } from "react";
import star from "../../../assets/svgs/big-star.svg";
import { motion } from "framer-motion";
import { CustomEase, gsap } from "gsap/all";
import { Link, useNavigate } from "react-router-dom";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import { ScrollTrigger } from "gsap/all";
import { useSelector } from "react-redux";
import PointerCursor from "../../GeneralComponents/PointerCursor";
import CrlBtn from "../../GeneralComponents/ClrBtn";
import { useGSAP } from "@gsap/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ProjectSection() {
  const ref = useRef(null);
  const pRef = useRef(null);
  const sectionRef = useRef(null);
  const [dataFetch, setDataFetch] = useState({ body: null });
  const url = useSelector((state) => state.ui.url);
  const nav = useNavigate();

  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate } = useSelector((state) => state.ui);
  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (dataHome?.data?.attributes?.body) {
        const body = dataHome.data.attributes.body.filter(
          (e) => e.__component === "page.project-menu"
        );
        const seo = dataHome.attributes?.SEO ?? null; // Optional chaining for SEO
        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };

    dataHandler();
  }, [dataHome, statusHome]);

  useGSAP(
    () => {
      let count = 0;
      let tick;
      let run = 0;
      gsap.registerPlugin(ScrollTrigger, CustomEase);
      CustomEase.create("fastSlowFast", "M0,0 C0.3,0.5 0.6,0.5 1,1");
      CustomEase.create("slowToFast", "M0,0 C0.95,.5 0.95,0.05 1,1");

      const animationHandler = () => {
        ScrollTrigger.create({
          trigger: ".project",
          start: "top 50%",
          once: false,

          onEnter: () => {
            gsap.set(ref.current, { y: 0 });
            gsap.fromTo(
              ref.current,
              { scale: 0 },
              {
                scale: window.innerHeight * 0.3,
                transformOrigin: "center center",

                xPercent: 10,
                y: 0,
                duration: 4,
                immediateRender: false,
                ease: "fastSlowFast-nb",
                onStart: function () {
                  tick = 0;
                },
                onUpdate: () => {
                  tick++;

                  if (tick % 40 === 0 && run === 0) {
                    run++;
                    gsap.to(".main-block", {
                      visibility: "visible",
                    });
                    gsap.to(".main-block-two", {
                      visibility: "visible",
                    });

                    gsap.set(".imgs_s", {
                      clipPath: "inset(0% 0% 100% 0%)", // Initial clip-path to hide the bottom part of the image
                    });

                    gsap.to(".imgs_s", {
                      clipPath: "inset(0% 0% 0% 0%)", // Reveals the image fully from top to bottom
                      duration: 4,
                      stagger: 0.4,
                      ease: "expo.out",
                    });

                    gsap.fromTo(
                      ".text-cover ",

                      {
                        clipPath: "inset(100% 0% 0% 0%)",
                      },
                      {
                        clipPath: "inset(0% 0% 0% 0%)",
                        delay: 2,
                        duration: 2,
                        stagger: 0.4,
                        ease: "expo.out",
                      }
                    );
                  }
                },
                onComplete: () => {
                  if (count === 0) {
                  }
                  count++;
                },
              }
            );
          },
          onLeaveBack: () => {
            gsap.to(ref.current, {
              scale: 0,
              y: 0,
              duration: 3,
              overwrite: true,
              ease: "fastToSlow-nb",
            });
          },
        });
      };

      const imgs = gsap.utils.toArray(".img");
      changeMenuColorBlack(pRef.current, "white");
      imgs.forEach((e) => {
        gsap.fromTo(
          e,
          { yPercent: -20 },
          {
            yPercent: 20,
            delay: 2,

            scrollTrigger: {
              trigger: e,
              start: "top bottom",
              end: () => `+=${sectionRef.current?.offsetHeight}`,
              scrub: true,
            },
          }
        );
      });
      animationHandler();
    },
    sectionRef.current,

    { scope: ref.current }
  );

  if (statusHome === "loading") {
    return <div>Loading...</div>;
  }

  if (statusHome === "failed") {
    return <div>Error: {dataHome}</div>;
  }

  return (
    <div>
      <section ref={pRef} className="project">
        <motion.div ref={ref} className="star-container">
          <img
            loading="lazy"
            src={star}
            alt="black star expanding"
            className="star"
          />
        </motion.div>
        <div ref={sectionRef}>
          <div className="text-container">
            <h2 className="project-title">
              {locate === "it" ? "Progetti" : "Best projects"}
            </h2>
            {locate === "it" && (
              <span className="project-mini-text">quelli belli</span>
            )}
          </div>
          <div className="main-block">
            <div className="first-img-container">
              <div className="img-container contain">
                <Link
                  to={
                    locate === "en"
                      ? "/en" +
                        `${
                          url?.Progetti +
                          "/" +
                          dataFetch.body?.ParallaxImage?.[0]?.Slug
                        }`
                      : url?.Progetti +
                        "/" +
                        dataFetch.body?.ParallaxImage?.[0]?.Slug
                  }
                >
                  <div>
                    <img
                      loading="eager"
                      className="imgs_s"
                      src={
                        process.env.REACT_APP_URL +
                        dataFetch.body?.ParallaxImage?.[0]?.Image?.data
                          ?.attributes?.url
                      }
                      alt={
                        dataFetch.body?.ParallaxImage?.[0]?.Image?.data
                          ?.attributes?.alternativeText
                      }
                    />
                  </div>
                </Link>
              </div>
              <motion.div className="text-cover">
                <h4 className="parall-title">
                  {dataFetch.body?.ParallaxImage?.[0]?.ImageTitle}
                </h4>
                <p className="parall-text">
                  {dataFetch.body?.ParallaxImage?.[0]?.ImageDescription}
                </p>
              </motion.div>
            </div>
            <div className="second-img-container">
              <div className="img-container contain">
                <Link
                  to={
                    locate === "en"
                      ? "/en" +
                        `${
                          url?.Progetti +
                          "/" +
                          dataFetch.body?.ParallaxImage?.[1]?.Slug
                        }`
                      : url?.Progetti +
                        "/" +
                        dataFetch.body?.ParallaxImage?.[1]?.Slug
                  }
                >
                  <img
                    loading="eager"
                    className="imgs_s"
                    src={
                      process.env.REACT_APP_URL +
                      dataFetch.body?.ParallaxImage?.[1]?.Image?.data
                        ?.attributes?.url
                    }
                    alt={
                      dataFetch.body?.ParallaxImage?.[1]?.Image?.data
                        ?.attributes?.alternativeText
                    }
                  />
                </Link>
              </div>

              <motion.div className="text-cover">
                <h4 className="parall-title">
                  {dataFetch.body?.ParallaxImage?.[1]?.ImageTitle}
                </h4>
                <p className="parall-text">
                  {dataFetch.body?.ParallaxImage?.[1]?.ImageDescription}
                </p>
              </motion.div>
            </div>
          </div>
          <div className="main-block-two">
            <div className="first-img-container">
              <div className="img-container contain">
                <Link
                  to={
                    locate === "en"
                      ? "/en" +
                        `${
                          url?.Progetti +
                          "/" +
                          dataFetch.body?.ParallaxImage?.[2]?.Slug
                        }`
                      : url?.Progetti +
                        "/" +
                        dataFetch.body?.ParallaxImage?.[2]?.Slug
                  }
                >
                  <img
                    loading="eager"
                    className="imgs_s"
                    src={
                      process.env.REACT_APP_URL +
                      dataFetch.body?.ParallaxImage?.[2]?.Image?.data
                        ?.attributes?.url
                    }
                    alt={
                      dataFetch.body?.ParallaxImage?.[2]?.Image?.data
                        ?.attributes?.alternativeText
                    }
                  />
                </Link>
              </div>
              <motion.div className="text-cover">
                <h4 className="parall-title">
                  {dataFetch.body?.ParallaxImage?.[2]?.ImageTitle}
                </h4>
                <p className="parall-text">
                  {dataFetch.body?.ParallaxImage?.[2]?.ImageDescription}
                </p>
              </motion.div>
            </div>
            <div className="second-img-container">
              <div className="img-container contain">
                <Link
                  to={
                    locate === "en"
                      ? "/en" +
                        `${
                          url?.Progetti +
                          "/" +
                          dataFetch.body?.ParallaxImage?.[3]?.Slug
                        }`
                      : url?.Progetti +
                        "/" +
                        dataFetch.body?.ParallaxImage?.[3]?.Slug
                  }
                >
                  <img
                    className="imgs_s"
                    loading="eager"
                    src={
                      process.env.REACT_APP_URL +
                      dataFetch.body?.ParallaxImage?.[3]?.Image?.data
                        ?.attributes?.url
                    }
                    alt={
                      dataFetch.body?.ParallaxImage?.[3]?.Image?.data
                        ?.attributes?.alternativeText
                    }
                  />
                </Link>
              </div>
              <motion.div className="text-cover">
                <h4 className="parall-title">
                  {dataFetch.body?.ParallaxImage?.[3]?.ImageTitle}
                </h4>
                <p className="parall-text">
                  {dataFetch.body?.ParallaxImage?.[3]?.ImageDescription}
                </p>
              </motion.div>
            </div>
          </div>
          <div
            onClick={() =>
              nav(locate === "en" ? `/en` + url?.Progetti : url?.Progetti)
            }
            className="project__btn"
          >
            <CrlBtn />
            <span>
              {locate === "it" ? "Ce ne sono di più :)" : "And even more!"}
            </span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default memo(ProjectSection);
