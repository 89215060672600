import { useEffect, useState, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import ContactSection from "../Compoents/Home/ContactSection/ContactSection";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import gsap from "gsap/all";
import { AnimatePresence, motion } from "framer-motion";
import Meta from "../Compoents/Meta/Meta";
import { blogAction } from "../State/Features/Blog/blogSlice";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BlogItem from "../Compoents/Blog/blog-item"; // Import BlogItem

function Blog({ loading }) {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const [word, setWord] = useState("");
  const { dataBlog } = useSelector((state) => state.blog);
  const { locate, footer } = useSelector((state) => state.ui);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCompelete, setIsComplete] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const swiperRef = useRef(null);

  const [isSwiperEnabled, setIsSwiperEnabled] = useState(
    window.innerWidth <= 700
  );

  useEffect(() => {
    dispatch(blogAction(`blog?locale=${locate}`));
  }, [locate, dispatch]);

  useEffect(() => {
    // Data destructuring
    const dataHandler = () => {
      if (dataBlog.data) {
        const data = dataBlog.data;
        const body = data.attributes.Blogs;
        const cate = data.attributes.Blog_categories;
        setDataFetch({
          body: body,
          cate: cate,
          seo: dataBlog.data?.attributes.SEO,
        });
      }
    };

    dataHandler();
  }, [dataBlog.data]);

  const search = dataFetch?.body?.filter((e) =>
    e?.Category.some((cat) =>
      cat?.Category.toLowerCase().includes(word.toLowerCase())
    )
  );

  useGSAP(
    () => {
      setTimeout(() => {
        let tl = gsap.timeline({
          onComplete: () => setIsComplete(true),
        });
        tl.to(".bog-title", {
          y: 0,
          opacity: 1,
          duration: 2,
          immediateRender: true,
          ease: "fastToSlow",
        });
        tl.to(
          ".blog_category-container",

          {
            y: 0,

            duration: 2,
            ease: "fastToSlow-nb",
            immediateRender: true,
          },
          "<40%"
        );
        tl.fromTo(
          ".blog_category-item",
          { yPercent: 100 },

          {
            yPercent: 0,
            stagger: 0.1,
            duration: 2,
            ease: "fastToSlow-nb",
            immediateRender: true,
          },
          "<0%"
        );
        tl.to(
          ".spacer-blog",
          {
            scaleY: 0,
            stagger: 0.1,
            transformOrigin: "bottom",
            duration: 3,
            ease: "fastToSlow-nb",
            immediateRender: true,
          },
          "<40%"
        );
      }, 1200);
    },
    { scope: ref.current }
  );

  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );

  const indexHandler = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) {
        setIsSwiperEnabled(false);
      } else {
        setIsSwiperEnabled(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header data={footer} />
      {dataFetch.body && (
        <>
          <main ref={ref} className="blog_page">
            <Meta data={dataFetch.seo} />
            <div className="blog_title-container">
              <h3 className="bog-title">Blog</h3>
            </div>
            {isSwiperEnabled ? (
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Swiper
                  style={{ paddingRight: "7rem" }}
                  ref={swiperRef}
                  slidesPerView="auto" /* Use 'auto' to allow each slide to maintain its original width */
                  spaceBetween={8} /* Space between slides */
                  breakpoints={{
                    700: {
                      slidesPerView: "auto",
                      spaceBetween: 8 /* Adjust the spaceBetween if necessary */,
                    },
                  }}
                  direction="horizontal"
                  className="blog_category-container"
                >
                  {dataFetch.cate.map((e, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => {
                        setWord(index === 0 ? "" : e.category);
                        indexHandler(index);
                      }}
                      className={
                        activeIndex === index
                          ? "blog_category-item active"
                          : "blog_category-item"
                      }
                    >
                      <span>{e.category}</span>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <div className="blog_category-container">
                {dataFetch.cate.map((e, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setWord(index === 0 ? "" : e.category);
                      indexHandler(index);
                    }}
                    className={
                      activeIndex === index
                        ? "blog_category-item active"
                        : "blog_category-item"
                    }
                  >
                    <span>{e.category}</span>
                  </div>
                ))}
              </div>
            )}
            <AnimatePresence mode="wait">
              {search.length === 0 ? (
                <>
                  <div style={{ position: "relative" }}>
                    <motion.div
                      initial={{ opacity: 0, transformOrigin: "top" }}
                      animate={{ opacity: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="spacer-blog"
                    ></motion.div>
                  </div>
                  <div className="no-blog">
                    <motion.span
                      layout
                      exit={{ opacity: 0 }}
                      transition={{ ease: "linear", duration: 0.5 }}
                      style={{ fontSize: "4rem", color: "white" }}
                    >
                      No blog found😔
                    </motion.span>
                  </div>
                </>
              ) : (
                search.map((e, i) => (
                  <motion.div
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "linear", duration: 1 }}
                    className="blog-per-item"
                    key={i}
                  >
                    <motion.div
                      animate={{ visibility: isCompelete && "hidden" }}
                      transition={{ ease: "linear", duration: 0.5 }}
                      className="spacer-blog"
                    ></motion.div>
                    <BlogItem
                      cat_1={e?.Category}
                      blog_title={e?.Blog_Title}
                      link={e?.Slug}
                      img_src={
                        process.env.REACT_APP_URL +
                        e?.Blog_Image.data.attributes?.url
                      }
                    />
                  </motion.div>
                ))
              )}
            </AnimatePresence>
          </main>
          <ContactSection />
          <FooterSection data={footer} />
        </>
      )}
      <Transition />
    </>
  );
}

export default memo(Blog);
