import smallStar from "../../../assets/svgs/small-star.svg";
import { motion } from "framer-motion";
import { Suspense, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ModelResponse } from "./ModelReponse";
import Meta from "../../Meta/Meta";
import { changeMenuColorBlack } from "../../MotionTools/Slide";
import { useDispatch, useSelector } from "react-redux";
import { memo } from "react";

import {
  apiReadyHandler,
  loadHandler,
} from "../../../State/Features/Ui/uiSlice";
import { useGSAP } from "@gsap/react";
import gsap from "gsap/all";

const MainLandSection = ({ isPreLoader }) => {
  const [height, setHight] = useState(null);
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const isMobileSmall = useMediaQuery({ maxWidth: 700 });
  const refElementH = useRef(null);
  const ref = useRef(null).current;
  const starRef = useRef(null);
  const { statusHome, dataHome } = useSelector((state) => state.home);
  const { locate, isLoading } = useSelector((state) => state.ui);
  const [isClass, setClass] = useState(false);
  const dispatch = useDispatch();

  // variants
  useEffect(() => {
    // data destructuring
    const dataHandler = () => {
      if (
        dataHome &&
        dataHome.data &&
        dataHome.data.attributes &&
        dataHome.data.attributes.body
      ) {
        const body = dataHome.data.attributes.body.filter(
          (e) => e.__component === "page.header"
        );
        const seo = dataHome.data.attributes.SEO;

        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };

    dataHandler();
  }, [dataHome, statusHome]);

  const firstChildMotion = {
    hidden: {
      scale: isMobileSmall
        ? window.innerHeight * 0.1
        : window.innerHeight * 0.2,
      x: isMobileSmall ? -window.innerHeight * 0.01 : -window.innerHeight * 0.5,
      y: -window.innerHeight * 0.4,
    },
    visible: {
      scale: 1,
      x: 0,
      y: 0,

      transition: {
        duration: 1.4,
        ease: "easeOut",
        delay: 3,
      },
    },
  };

  useGSAP(
    () => {
      changeMenuColorBlack(ref, "black");
    },
    ref,
    { scope: ref }
  );

  useEffect(() => {
    const height = refElementH.current?.offsetHeight;
    changeMenuColorBlack(ref, "black");
    setHight(height);
  }, [ref]);

  const parentContainer = {
    hidden: {},
    visible: { transition: { straggerChildern: 3 } },
  };

  const textHeightMotion = {
    hidden: {
      y: refElementH.current ? height : 500,
      opacity: 1,
    },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.9,
        delay: isPreLoader ? i + 4.1 : 0.6,
        ease: [0.76, 0, 0.24, 1],
      },
    }),
  };
  function getAdjustedTextWidth(text, font = "16px Arial") {
    // Create a canvas element
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set the font to the context
    context.font = font;

    // Split the text into words and get the last word
    const words = text.trim().split(/\s+/);
    const lastWord = words[words.length - 1];

    // Measure the width of the last word
    const metrics = context.measureText(lastWord);
    return metrics.width;
  }

  useEffect(() => {
    const starPosition = () => {
      const star = starRef?.current;
      if (star && locate === "en" && isMobileSmall) {
        const proText = getAdjustedTextWidth(
          findPresntageHandler(
            dataFetch.body.BeautifulProjects,
            /\b[a-zA-Z]+\b/g
          ).join(" "),
          "25rem Arial"
        );

        if (proText && !isNaN(proText)) {
          star.style.position = "absolute";
          star.style.left = `${proText * 0.06}rem`;
        } else {
          console.error("Invalid proText calculation");
        }
      } else {
        if (star) {
          star.style.left = "0";
        }
      }
    };

    if (isLoading && dataFetch?.body) {
      window.addEventListener("resize", starPosition);
      starPosition();
    }

    return () => {
      window.removeEventListener("resize", starPosition);
    };
  }, [isLoading, dataFetch?.body, locate, isMobileSmall]);

  const findPresntageHandler = (words, exp) => {
    return words.match(exp);
  };

  if (statusHome === "loading") {
    dispatch(apiReadyHandler(false));
    return <div>Loading...</div>;
  }

  if (statusHome === "failed") {
    // throw new Error();
  }

  return (
    <>
      <motion.section
        ref={ref}
        variants={parentContainer}
        initial="hidden"
        animate="visible"
        className="main-section"
      >
        <Meta data={dataFetch.seo} />
        <div className="main-section__level0-container">
          <div style={{ width: "100%" }}>
            <motion.p layout variants={textHeightMotion} custom={0}>
              {dataFetch.body && dataFetch.body.UnconventionalCreativeAgency}
            </motion.p>
          </div>
          <div className="spacer"></div>
          <div className="model-container">
            <Suspense>
              <ModelResponse isPreLoader={isPreLoader} />
            </Suspense>
          </div>
        </div>
        <div className="main-section__level1-container">
          <div>
            <motion.p
              layout
              variants={textHeightMotion}
              custom={0.1}
              className="fuffa"
            >
              <span className="zero">
                {dataFetch.body &&
                  findPresntageHandler(dataFetch.body.Fluff, /\d+%/)}
              </span>
              {dataFetch.body &&
                findPresntageHandler(dataFetch.body.Fluff, /\b[a-zA-Z]+\b/g)}
            </motion.p>
          </div>

          {!isMobileSmall && (
            <div className="main-section__nested-container">
              <motion.img
                variants={textHeightMotion}
                custom={0.15}
                className={`small-star ${isClass && "rotate"}`}
                src={smallStar}
                alt="small star"
              />
              <motion.p
                variants={textHeightMotion}
                custom={0.18}
                className="text"
                onAnimationComplete={() => setClass(true)}
              >
                {dataFetch.body && dataFetch.body.MeasurableResults}
              </motion.p>
            </div>
          )}
        </div>
        <div className="level2-main-container">
          <div ref={refElementH} className="main-section__level2-container">
            <motion.p variants={textHeightMotion} custom={0.3} className="pro">
              <span className="hunderd">
                {dataFetch.body &&
                  findPresntageHandler(
                    dataFetch.body.BeautifulProjects,
                    /\d+%/
                  )}
              </span>{" "}
              {dataFetch.body &&
                findPresntageHandler(
                  dataFetch.body.BeautifulProjects,
                  /\b[a-zA-Z]+\b/g
                ).join(" ")}
            </motion.p>
          </div>
          {dataFetch.body && (
            <>
              <div className="star-container">
                {isPreLoader ? (
                  <motion.div
                    variants={firstChildMotion}
                    className="loading-star"
                    ref={starRef}
                    onAnimationStart={() =>
                      gsap.set(".main-section", { zIndex: 999 })
                    }
                    onAnimationComplete={() => {
                      dispatch(loadHandler(true));
                      gsap.set(".main-section", { zIndex: 9 });
                    }}
                    initial="hidden"
                    animate="visible"
                  >
                    <motion.svg
                      width="61"
                      height="61"
                      viewBox="0 0 61 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.4208 61L26.4597 45.3549L15.2104 56.8912L19.4883 41.4041L3.96104 45.671L15.5273 34.4508L0 30.5L15.5273 26.3912L3.96104 15.171L19.4883 19.4378L15.2104 4.10881L26.4597 15.487L30.4208 0L34.5403 15.487L45.7896 4.10881L41.5117 19.4378L57.039 15.171L45.4727 26.3912L61 30.5L45.4727 34.4508L57.039 45.671L41.5117 41.4041L45.7896 56.8912L34.5403 45.3549L30.4208 61Z"
                        fill="black"
                      />
                    </motion.svg>
                  </motion.div>
                ) : (
                  <motion.div ref={starRef} className="loading-star">
                    <motion.svg
                      style={{ position: "absolute", animationDelay: "0s" }}
                      width="61"
                      height="61"
                      viewBox="0 0 61 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.4208 61L26.4597 45.3549L15.2104 56.8912L19.4883 41.4041L3.96104 45.671L15.5273 34.4508L0 30.5L15.5273 26.3912L3.96104 15.171L19.4883 19.4378L15.2104 4.10881L26.4597 15.487L30.4208 0L34.5403 15.487L45.7896 4.10881L41.5117 19.4378L57.039 15.171L45.4727 26.3912L61 30.5L45.4727 34.4508L57.039 45.671L41.5117 41.4041L45.7896 56.8912L34.5403 45.3549L30.4208 61Z"
                        fill="black"
                      />
                    </motion.svg>
                  </motion.div>
                )}
              </div>
            </>
          )}
        </div>

        {isMobileSmall && (
          <div className="main-section__nested-container below">
            <motion.img
              layout
              variants={textHeightMotion}
              custom={0.5}
              className={`small-star ${isClass && "rotate"}`}
              src={smallStar}
              alt="small star"
            />
            <motion.p
              layout
              variants={textHeightMotion}
              custom={0.1}
              className="text"
              onAnimationComplete={() => setClass(true)}
            >
              {dataFetch.body && dataFetch.body.MeasurableResults}
            </motion.p>
          </div>
        )}
      </motion.section>
    </>
  );
};

export default memo(MainLandSection);
