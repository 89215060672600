import { useRef } from "react";
import CrlBtn from "../Compoents/GeneralComponents/ClrBtn";
import dog from "../assets/images/dog.webp";
import { useNavigate } from "react-router-dom";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import FooterSection from "../Compoents/FooterSection";
import { useSelector } from "react-redux";
import Header from "../Compoents/Header";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { useGSAP } from "@gsap/react";
function ThankYou() {
  const nav = useNavigate();
  const ref = useRef(null);
  const { locate, footer } = useSelector((state) => state.ui);

  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );

  return (
    <>
      <Header data={footer} />
      <section ref={ref} className="thank-you">
        <div className="left-container">
          <h3 className="title">
            {locate === "it" ? "Mossa geniale!" : "Excellent move!"}
          </h3>
          <p className="paragraph">
            {locate === "it"
              ? "Grande: hai compilato il form! Adesso che hai rotto il ghiaccio, non perdiamoci di vista."
              : "Great, you filled in the form. Now that the ice is broken, let’s keep in touch."}
            <br />
            <br />
            {locate === "it"
              ? "Ci vediamo nella casella di posta!"
              : "See you in your email inbox!"}
          </p>
          <div
            className={
              locate === "it"
                ? "ts btn-text-container"
                : "tl btn-text-container"
            }
          >
            <CrlBtn onClick={() => nav("/")} />
            {locate === "it" ? (
              <span>
                Torna <br /> alla home
              </span>
            ) : (
              <span>
                Back to the <br /> homepage
              </span>
            )}
          </div>
        </div>
        <div className="right-container">
          <img src={dog} alt="a funny dog with a drink" />
        </div>
      </section>
      <FooterSection data={footer} />
      <Transition />
    </>
  );
}

export default ThankYou;
