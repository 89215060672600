// BlogCardList.js
import BlogCard from "../../GeneralComponents/BlogCard";

const BlogCardList = ({ dataFetch, handleBlogDetail, colors, url, locate }) => {
  return (
    <div className="card-wrapper">
      {dataFetch.map((e, index) => (
        <div key={index} className="cards">
          <BlogCard
            blogTitle={e.Blog_Title}
            blogDescription={handleBlogDetail(e.Slug)}
            cat_1={e.Category}
            style={colors[index]}
            link={e.Slug}
            fill={index === 1 ? "white" : "black"}
            src={`${process.env.REACT_APP_URL}${e.Blog_Image.data?.attributes.url}`}
            url={locate === "en" ? `/${locate}` + url.Blog : url.Blog}
          />
        </div>
      ))}
    </div>
  );
};

export default BlogCardList;
