import { motion, AnimatePresence } from "framer-motion";
import { Suspense, useEffect, useRef, useState } from "react";
import smallStar from "../assets/svgs/small-star.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SoicalMedia from "./GeneralComponents/SoicalMedia";
import { lenis } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { ModelMenu } from "./ModelMenu";
import {
  localeHandler,
  positionHandler,
  transitionHandler,
} from "../State/Features/Ui/uiSlice";
import { FTKHeader } from "./Lottie/FTK";

const LineSVG = ({ className }) => {
  return (
    <motion.svg
      className={`${className} lines`}
      viewBox="0 0 607 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        id="path"
        d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1063C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3602C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3602C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5258 59.7513 11.2251C47.5419 9.99207 32.0867 8.36974 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
        stroke="#5DF814"
        stroke-width="3.5"
        stroke-linecap="round"
        transform="scale(1, 1.2)"
      />
    </motion.svg>
  );
};

const Header = ({ data }) => {
  const mobile = useMediaQuery({ maxWidth: 700 });
  const [headerShow, setHeaderShow] = useState(false);
  const [onClick, setClick] = useState(false);
  const locate = useSelector((state) => state.ui.locate);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const refNav = useRef(null);

  const location = useLocation();

  const parantTranMotion = {
    hidden: {},
    visible: {},
  };

  const nav = useNavigate();
  const dispatch = useDispatch();
  const dispatchIsMenu = () => {
    dispatch(transitionHandler(false));
  };

  const [dataFetch, setDataFetch] = useState({ body: null });
  const [socialLinks, setSocialLinks] = useState({ body: null });

  useEffect(() => {
    if (data) {
      const nav = data.data?.attributes?.Nav;
      const footer = data.data?.attributes?.Footer;
      setDataFetch({ body: nav });
      setSocialLinks({ body: footer });
    }
  }, [data]);

  useEffect(() => {
    setTimeout(
      () => {
        setHeaderShow(true);
      },
      window.location.pathname === "/" ? 2700 : 0
    );
  }, []);

  return (
    <div>
      {dataFetch.body && (
        <motion.header
          id="head"
          variants={parantTranMotion}
          initial="hidden"
          animate="visible"
          className="header"
        >
          <nav className="header__nav">
            <h1
              className="header__title nav-colorful"
              onClick={() => {
                nav(locate === "en" ? `/en` : "/");
              }}
            >
              {!headerShow && window.location.pathname === "/" && <FTKHeader />}
              {headerShow && (
                <div style={{ width: mobile ? "13.5rem" : "7.85rem" }}>
                  <svg
                    style={{ width: "100%" }}
                    viewBox="0 0 62 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5609_705)">
                      <path
                        className="nav-colorful"
                        d="M31.4444 32.9704C30.0225 32.9704 29.0314 32.5304 28.4734 31.6505C27.9153 30.7705 27.6351 29.3302 27.6351 27.3318L27.5842 15.0379L35.5593 15.1398V10.314H27.6351V1.93127H21.3874V10.314H10.7701C10.7007 9.77209 10.6683 9.24644 10.6683 8.73931C10.6683 6.30092 11.8353 5.08056 14.1742 5.08056C15.4269 5.08056 16.7307 5.3515 18.0853 5.89336L19 1.37319C17.1706 0.458504 15.1375 0.00231934 12.9029 0.00231934C10.1588 0.00231934 8.07703 0.782697 6.65289 2.33882C5.23108 3.89726 4.52017 5.94662 4.52017 8.4869C4.52017 8.92688 4.57112 9.5359 4.673 10.3163H0V15.1421L4.87678 15.0402L4.77489 37.2405H11.0735L10.9207 15.0402H21.4361L21.3851 26.6741C21.3504 29.182 21.5194 31.2128 21.8923 32.7712C22.2651 34.3297 23.0941 35.5917 24.3816 36.555C25.6691 37.5207 27.6166 38.0023 30.224 38.0023C32.3591 38.0023 34.3714 37.5971 36.2702 36.782L35.1541 32.3637C33.8665 32.7689 32.63 32.9727 31.4444 32.9727V32.9704Z"
                        fill={"black"}
                      />
                      <path
                        className="nav-colorful"
                        d="M51.4841 20.3709L60.6287 10.3117H53.4154L43.7104 21.4384V19.3543L43.8123 1.97992H37.5137L37.6666 19.3543L37.5137 37.2382H43.8123L43.7614 28.8555L47.4201 24.8424L55.0896 37.2382H61.9995L51.4841 20.3709Z"
                        fill={"black"}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5609_705">
                        <rect width="61.9995" height="38" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
            </h1>
            <motion.span
              style={{ zIndex: onClick ? 0 : 100 }}
              onClick={() => {
                setClick(true);
                // lenis.stop();
                dispatch(positionHandler(window.scrollY));
              }}
              className="nav-menu nav-colorful"
            >
              MENU
            </motion.span>

            <AnimatePresence mode="wait">
              {onClick && (
                <motion.div
                  className="list-container"
                  exit={{ display: "none", transition: { delay: 1 } }}
                >
                  <motion.img
                    initial={{ scale: 1, x: 0, y: -30 }}
                    animate={{
                      scale: window.innerWidth * 0.29,
                      x: -window.innerWidth / 1.6,
                      y: isTabletOrMobile
                        ? window.innerHeight / 2.5
                        : window.innerHeight / 1.2,
                      transition: { duration: 1.5, ease: "easeOut" },
                    }}
                    exit={{
                      scale: 1,
                      x: 0,
                      y: -30,
                      opacity: 0,
                      transition: {
                        ease: "easeOut",
                        duration: 1,
                        opacity: { delay: 1 },
                      },
                    }}
                    className="menu__star"
                    src={smallStar}
                    alt="small star"
                  />
                  <div className="menu-scroll">
                    <motion.p
                      className="menu-close"
                      exit={{ opacity: 0 }}
                      onClick={() => {
                        setClick(false);
                        lenis.start();
                      }}
                    >
                      CHIUDI
                    </motion.p>
                    <motion.div exit={{ opacity: 0 }} className="menu-lang">
                      <span
                        className={locate == "it" ? "" : "active"}
                        onClick={() => {
                          if (locate === "en") {
                            dispatch(localeHandler("it"));
                            nav(window.location.pathname.replace("/en/", "/"));
                          }
                        }}
                      >
                        ITA
                      </span>
                      <span
                        className={locate == "en" ? "" : "active"}
                        onClick={() => {
                          if (locate === "it") {
                            dispatch(localeHandler("en"));
                            nav(location.pathname.replace("/", "/en/"));
                          }
                        }}
                      >
                        ENG
                      </span>
                    </motion.div>
                    <motion.ul
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      exit={{ opacity: 0 }}
                      className="nav-list"
                    >
                      <li className="list-item">
                        <div className="lines-container">
                          {/* Link to Progetti */}
                          <Link
                            className="items"
                            onClick={() => {
                              setClick(false);
                              lenis.start();
                              dispatchIsMenu();
                            }}
                            to={
                              dataFetch.body &&
                              (locate === "en"
                                ? `/en` + dataFetch.body.Progetti
                                : dataFetch.body.Progetti)
                            }
                          >
                            {dataFetch.body && dataFetch.body.Projetti_Nav}
                            <LineSVG />
                          </Link>

                          {/* Link to Filosofia */}
                          <Link
                            className="items"
                            onClick={() => {
                              setClick(false);
                              lenis.start();
                              dispatchIsMenu();
                            }}
                            to={
                              dataFetch.body &&
                              (locate === "en"
                                ? `/en` + dataFetch.body.Filosofia
                                : dataFetch.body.Filosofia)
                            }
                          >
                            {dataFetch.body && dataFetch.body.Filosofia_Nav}
                            <motion.svg
                              className={`${
                                locate === "it" ? "s lines" : " l lines"
                              }`}
                              ref={refNav}
                              viewBox="0 0 607 67"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <motion.path
                                id="path"
                                d="M81.0549 39.4681C86.8839 39.1885 100.534 39.9009 104.444 40.1063C152.921 42.6535 201.101 46.6735 249.547 49.401C355.87 55.387 462.851 60.7386 569.549 64.3602C573.436 64.4922 591.585 65.0296 597.556 64.9985C599.394 64.989 606.057 65.1053 604.856 64.3602C603.437 63.479 593.492 61.3641 592.491 61.1689C576.356 58.0233 558.788 56.4128 542.137 54.3874C424.951 40.1328 307.483 28.7439 188.169 21.0383C145.165 18.261 102.339 15.5258 59.7513 11.2251C47.5419 9.99207 32.0867 8.36974 19.6021 6.75724C15.7565 6.26054 6.51253 5.39935 2.99121 3.56594C-2.9914 0.451019 19.7195 2.86678 28.0193 3.32659C88.0859 6.65431 147.848 11.3807 207.983 14.5759C310.852 20.0418 414.008 23.1816 517.407 23.352C527.356 23.3684 537.817 23.2323 549.065 23.2323"
                                stroke="#5DF814"
                                strokeWidth="3.5"
                                strokeLinecap="round"
                                transform="scale(1, 1.2)"
                              />
                            </motion.svg>
                          </Link>

                          {/* Link to Blog */}
                          <Link
                            className="items"
                            onClick={() => {
                              setClick(false);
                              lenis.start();
                              dispatchIsMenu();
                            }}
                            to={
                              dataFetch.body &&
                              (locate === "en"
                                ? `/en` + dataFetch.body.Blog
                                : dataFetch.body.Blog)
                            }
                          >
                            {dataFetch.body && dataFetch.body.Blog_Nav}
                            <LineSVG />
                          </Link>

                          {/* Link to Contatti */}
                          <Link
                            className="items"
                            onClick={() => {
                              setClick(false);
                              lenis.start();
                              dispatchIsMenu();
                            }}
                            to={
                              dataFetch.body &&
                              (locate === "en"
                                ? `/en` + dataFetch.body.Contatti
                                : dataFetch.body.Contatti)
                            }
                          >
                            {dataFetch.body && dataFetch.body.Contatti_Nav}
                            <LineSVG />
                          </Link>
                        </div>
                      </li>

                      <motion.div
                        initial={{ width: "70%", height: "70%" }}
                        animate={{ width: "55%", height: "55%", top: "5%" }}
                        transition={{}}
                        className="model-menu"
                      >
                        <Suspense>
                          <ModelMenu isPreLoader={false} click={onClick} />
                        </Suspense>
                      </motion.div>

                      <div className="soical-menu">
                        <SoicalMedia
                          data={socialLinks.body}
                          customClass="color-yallow"
                        />
                        <div className="policy-container">
                          <p
                            onClick={() =>
                              nav(
                                locate === "en"
                                  ? `/en` + `/privacy`
                                  : `/privacy`
                              )
                            }
                            className="policy"
                          >
                            Privacy policy
                          </p>
                          <p
                            onClick={() =>
                              nav(
                                locate === "en" ? `/en` + `/policy` : `/policy`
                              )
                            }
                            className="cookie"
                          >
                            Cookies policy
                          </p>
                          <p className="copy-rights">&copy;fotonika</p>
                        </div>
                      </div>
                    </motion.ul>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </nav>
        </motion.header>
      )}
    </div>
  );
};

export default Header;
