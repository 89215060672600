import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  changeMenuColorBlack,
  sildeHnadler,
  slideUpHnadler,
} from "../MotionTools/Slide";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Meta from "../Meta/Meta";
import gsap, { ScrollTrigger } from "gsap/all";
import Header from "../Header";
import { useGSAP } from "@gsap/react";

function IntroSection() {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const { dataFolosify, statusFolosify } = useSelector(
    (state) => state.folosify
  );
  const { footer, locate } = useSelector((state) => state.ui);

  const ref = useRef(null);

  const searchWordHandler = (words) => {
    let count = 0;
    let indexOne;
    let indexTwo;
    let sum = "";
    let htmlContent = "";
    // Variable to accumulate HTML content

    for (let i = 0; i < words.length; i++) {
      if (words[i].includes("{")) {
        count++;
        indexOne = i;
      } else if (words[i].includes("}")) {
        count++;
        indexTwo = i;
      } else if (count < 1) {
        sum += words[i];
      } else if (count === 2) {
        // Accumulate non-bracket content
        htmlContent += `<span>${sum}</span>`;
        htmlContent += ` <span class="big" >${words.substring(
          indexOne + 1,
          indexTwo
        )}</span>`;
        // Add content inside brackets
        count = 0;
        sum = "";
      }
      if (i === words.length - 1) {
        htmlContent += `<span>${sum}</span>`;
      }
    }
    return htmlContent;
  };

  useEffect(() => {
    const dataHandler = () => {
      if (
        dataFolosify &&
        dataFolosify.data &&
        dataFolosify.data.attributes &&
        dataFolosify.data.attributes.body
      ) {
        const body = dataFolosify.data.attributes.body.filter(
          (e) => e.__component === "filosofy-page.folosify-header"
        );
        const seo = dataFolosify.data.attributes.SEO;
        setDataFetch({
          body: body.length > 0 ? body[0] : null,
          seo: seo,
        });
      }
    };
    dataHandler();
  }, [dataFolosify]);
  useLayoutEffect(() => {
    if (dataFetch.body) {
      ScrollTrigger.create({
        trigger: ".second-block-container",
        start: "top 100%",

        onEnter: function () {
          gsap.to(".a-word", {
            y: 0,
            duration: 2,
            delay: 1.2,
            ease: "circ.in",
          });
        },
      });
      ScrollTrigger.create({
        trigger: ".second-block-container",
        start: "top 100%",

        onEnter: function () {
          gsap.to(".a-word-container", {
            y: 0,
            duration: 2,
            delay: 1.2,
            ease: "circ.in",
          });
        },
      });
    }
  }, [dataFetch.body, ref.current]);

  useGSAP(
    () => {
      sildeHnadler(ref.current, ".slide-intro");
      slideUpHnadler(".first-block-container", 3.5);
      slideUpHnadler(".second-block-container", 3, 0.5);

      gsap.to(".a-word", {
        y: 0,
        duration: 2,
        delay: 3,
        ease: "circ.in",
        scrollTrigger: {
          trigger: ".second-block-container",
          start: "top top",
          end: "bottom bottom",
        },
      });
      gsap.to(".a-word-container", {
        y: 0,
        duration: 2,
        delay: 3,
        ease: "circ.in",
        scrollTrigger: {
          trigger: ".second-block-container",
          start: "top top",
          end: "bottom bottom",
        },
      });

      changeMenuColorBlack(ref.current, "black");
    },
    ref.current,
    { scope: ref.current }
  );

  if (statusFolosify === "loading") {
    return <div>Loading...</div>;
  }

  if (statusFolosify === "failed") {
    return <div>Error: {dataFolosify}</div>;
  }
  return (
    <>
      {dataFetch.body && (
        <>
          <Header data={footer} />
          <section ref={ref} className="intro-section">
            <Meta data={dataFetch.seo} />
            <div className="intro-top-wrapper">
              <h1 className="intro-top-text">
              {locate === "it" &&   <div className="hidden">
                  <span className="slide-intro">
                    
                    Siamo l’agenzia
                  </span>
                
                </div>
}
                <div className="hidden">
                  <div className="purpal-cover">
                    {locate === "it" && (
                      <span className="slide-intro">creativa</span>
                    )}
                  </div>
                  <span className="slide-intro" style={{paddingBottom:0}}>
                    {locate === "en" && "We are" }
                  </span>
                  <div className="purpal-cover">
                    <motion.svg
                      style={{ width: "100%", height: "100%" }}
                      width="267"
                      height="57"
                      viewBox="0 0 267 57"
                      fill="none"
                      xmlns="http://www.w3.org/2000/motion."
                    >
                      <motion.path
                        initial={{ pathLength: 0 }}
                        whileInView={{ pathLength: 1 }}
                        transition={{ delay: 2, duration: 1.5 }}
                        d="M266.253 14.6634C260.522 12.8455 254.838 11.6259 242.633 9.28184C195.114 0.155576 145.804 -1.56646 97.7147 3.47789C82.3557 5.08899 66.976 7.20606 51.9822 10.9226C41.8262 13.4399 31.5609 16.6106 22.6341 22.0881C18.3314 24.7282 12.0199 29.6301 13.7516 34.3805C16.1058 40.8382 24.6799 43.0736 31.1299 45.3928C55.7831 54.2574 88.0781 55.736 113.314 56.0412C145.68 56.4326 176.809 54.4406 208.57 48.0302C220.349 45.6529 232.355 43.2867 243.272 38.2498C256.296 32.2415 262.89 21.6086 244.963 14.6746C232.872 9.99839 219.696 8.5074 206.797 7.25214C166.979 3.37728 128.364 5.93455 88.6382 9.72637C70.9604 11.4137 53.3358 13.7294 35.7942 16.506C-2.65647 22.5922 -17.9605 33.1657 34.8329 42.9672C88.6382 52.9565 77.4543 51.7216 142.126 53.8814C193.863 55.6093 201.507 52.9565 209.276 52.0315"
                        stroke="#5DF814"
                        stroke-width="1.10993"
                        stroke-linecap="round"
                      />
                    </motion.svg>
                   
                    <span className="purpal-non slide-intro" style={{paddingBottom:0}}>
                      {locate === "en" ? "the" : "non"}
                    </span>
                  </div>
                  {locate === "en" && (
                    <span className="slide-intro">unconventional</span>
                  )}
                </div>
                <div className="hidden">
                  <span className="slide-intro">
                    {locate === "en" ? "creative agency" : "convenzionale︎"}
                  </span>
                </div>
              </h1>
            </div>
            <div className="intro-bottom-wrapper">
              <div className="first-block-container">
                <p
                  dangerouslySetInnerHTML={{
                    __html: searchWordHandler(
                      dataFetch.body.Integrated_Marketing_and_Communication
                    ),
                  }}
                ></p>
              </div>
              <div className="second-block-container">
                <p>
                  <ReactMarkdown rehypePlugins={rehypeRaw}>
                    {dataFetch.body.Value_Driven_Strategic_Marketing}
                  </ReactMarkdown>
                </p>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default IntroSection;