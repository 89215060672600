// src/features/home/homeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ScrollTrigger } from "gsap/all";

export const homeAction = createAsyncThunk("home/fetchHome", async (props) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API}/${props}&populate=deep`,
    {
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
  );
  return response.data;
});

const homeSlice = createSlice({
  name: "home",
  initialState: {
    statusHome: "",
    dataHome: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(homeAction.pending, (state) => {
        state.statusHome = "loading";
      })
      .addCase(homeAction.fulfilled, (state, action) => {
        state.statusHome = "succeeded";
        state.dataHome = action.payload;
      })
      .addCase(homeAction.rejected, (state, action) => {
        state.statusHome = "failed";
        state.error = action.error.message;
      });
  },
});

export default homeSlice.reducer;
