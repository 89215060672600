// src/features/folosify/folosifySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const folosifyAction = createAsyncThunk(
  "folosify/fetchFolosify",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const folosifySlice = createSlice({
  name: "folosify",
  initialState: {
    statusFolosify: "",
    dataFolosify: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(folosifyAction.pending, (state) => {
        state.statusFolosify = "loading";
      })
      .addCase(folosifyAction.fulfilled, (state, action) => {
        state.statusFolosify = "succeeded";
        state.dataFolosify = action.payload;
      })
      .addCase(folosifyAction.rejected, (state, action) => {
        state.statusFolosify = "failed";
        state.error = action.error.message;
      });
  },
});

export default folosifySlice.reducer;
