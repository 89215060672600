import { useMediaQuery } from "react-responsive";
import { Canvas } from "@react-three/fiber";
import { motion as m } from "framer-motion-3d";
import { Suspense, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export const ModelResponse = ({ isPreLoader }) => {
  const [mashOne, setmashRefOne] = useState(null);
  const [mashTwo, setmashRefTwo] = useState(null);
  const [mashThree, setmashRefThree] = useState(null);
  const [mashOneP, setmashRefOneP] = useState(null);
  const [mashTwoP, setmashRefTwoP] = useState(null);
  const [mashThreeP, setmashRefThreeP] = useState(null);
  const mashRefOne = useRef();
  const mashRefTwo = useRef();
  const mashRefThree = useRef();
  const { nodes: f } = useGLTF("/models/F_lucido.glb");
  const { nodes: t } = useGLTF("/models/T_lucido.glb");
  const { nodes: k } = useGLTF("/models/K_lucido.glb");
  const mobile = useMediaQuery({ maxWidth: 1000 });



  const tl = useRef(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (mashRefOne.current && mashRefTwo.current && mashRefThree.current) {
        setmashRefOne(mashRefOne.current.rotation);
        setmashRefTwo(mashRefTwo.current.rotation);
        setmashRefThree(mashRefThree.current.rotation);
        setmashRefOneP(mashRefOne.current.position);
        setmashRefTwoP(mashRefTwo.current.position);
        setmashRefThreeP(mashRefThree.current.position);
      }
    }, 500);
    // Update state based on current ref values
    return () => {
      clearTimeout(timer);
    };
    // Keep running whenever mashRefOne, mashRefTwo, or mashRefThree changes
  }, [mashRefOne.current, mashRefTwo.current, mashRefThree.current]);
  const isLoading = useSelector((state) => state.ui.isLoading);
  useEffect(() => {
    const animateOnLoad = () => {
      if (mashOneP && mashTwoP && mashThreeP && isLoading) {
        setTimeout(() => {
          let tl = gsap.timeline({
            defaults: { duration: 1, ease: "expo.out" },
          });
          tl.fromTo(mashOneP, { y: -10 }, { y: 0.8 });
          tl.fromTo(mashTwoP, { y: -10 }, { y: -0.6 }, "<4%");
          tl.fromTo(mashThreeP, { y: -10 }, { y: 0.4 }, "<6%");
        }, 200);
      }
    };
    const animateModel = () => {
      tl.current = gsap.timeline();
      if (mashOne && mashTwo && mashThree) {
        tl.current.fromTo(
          mashThree,
          { x: 0.4, y: -0.2, z: 0.5, position: "sticky", top: 0 },
          {
            scrollTrigger: {
              target: ".main-section",
              start: "top top",
              end: "+=100%",
              scrub: 4,
            },
            x: Math.PI * 1.5,
            duration: 2,
          },
          0
        );
        tl.current.fromTo(
          mashOne,
          { x: 0, y: 0.5, z: 0.4, position: "sticky", top: 0 },
          {
            scrollTrigger: {
              target: ".main-section",
              start: "top top",
              end: "+=100%",

              scrub: 2,
            },
            x: Math.PI * 1,
            duration: 2,
          },
          0
        );
        tl.current.fromTo(
          mashTwo,
          { x: -0.2, y: -0.25, z: -0.4, position: "sticky", top: 0 },
          {
            scrollTrigger: {
              target: ".main-section",
              start: "top top",
              end: "+=100%",

              scrub: 3.8,
            },
            x: Math.PI * 2,
            duration: 2,
          },
          0
        );
      }
    };

    animateModel();
    animateOnLoad();
  }, [
    mashOne,
    mashThree,
    mashTwo,
    mashOneP,
    mashThreeP,
    mashTwoP,
    isLoading,
    mobile,
  ]);
  useEffect(() => {
    const onLoad = () => {
      ScrollTrigger.refresh();
    };

    if (mashRefOne.current && mashRefTwo.current && mashRefThree.current) {
      onLoad();
    }
  }, [mashRefOne, mashRefTwo, mashRefThree]);

  const opacityModel = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, delay: isPreLoader ? 4 : 0 },
    },
  };

  return (
    <Canvas
      className="sence"
      flat
      linear
      style={{
        position: "relative",
      }}
    >
      <directionalLight
        position={[0, 40, 30]}
        intensity={0.6}
        color="#fff"
        shadow-mapSize={[1024, 1024]}
      >
        <orthographicCamera attach="shadow-camera" args={[-10, 10, 10, -10]} />
      </directionalLight>
      <ambientLight intensity={0.5} color="#fff" />

      <Suspense>
        <m.group>
          <m.mesh
            position={[mobile ? -2.4 : -3.2, -10, -0.8]}
            ref={mashRefOne}
            className="three"
            rotation={[0.1, 0.5, 0.4]}
            geometry={f.F.geometry}
            scale={mobile ? 0.03 : 0.035}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>
          <m.mesh
            position={[mobile ? -0.4 : -0.3, -10, 0]}
            ref={mashRefTwo}
            className="three"
            // rotateX={1.3}

            rotation={[0, -0.25, -0.4]}
            geometry={t.T.geometry}
            scale={mobile ? 0.025 : 0.032}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>

          <m.mesh
            position={[mobile ? 1.6 : 2.7, -10, 1]}
            ref={mashRefThree}
            rotation={[0.4, -0.2, 0.5]}
            geometry={k.K.geometry}
            scale={mobile ? 0.02 : 0.025}
          >
            <m.meshPhongMaterial
              color="rgba(107, 34, 255, 1)"
              specular="#fff"
              shininess="100"
              initial="hidden"
              animate="visible"
              variants={opacityModel}
            />
          </m.mesh>
        </m.group>
      </Suspense>
    </Canvas>
  );
};
