import gsap, { ScrollTrigger } from "gsap/all";
window.addEventListener("load", function () {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.refresh();
});

export const changeMenuColorBlack = (el, color) => {
  const addColorClass = () => {
    document.querySelectorAll(".nav-colorful").forEach((element) => {
      element.classList.add(color);
    });
  };

  const removeColorClass = () => {
    document.querySelectorAll(".nav-colorful").forEach((element) => {
      element.classList.remove(color);
    });
  };

  ScrollTrigger.create({
    trigger: el,
    start: "top 10%",

    end: () => `+=${el?.offsetHeight}`,
    onEnter: addColorClass,
    onEnterBack: addColorClass,
    onLeave: removeColorClass, // Slight delay to handle race conditions
    onLeaveBack: removeColorClass, // Slight delay to handle race conditions
  });
};

export const sildeHnadler = (el, collection) => {
  const slide = gsap.utils.toArray(collection);
  const tl = gsap.timeline();
  setTimeout(() => {
    slide.forEach((e, index) => {
      gsap.fromTo(
        e,
        { display: "inline-block" },
        {
          y: 0,
          delay: index === 0 ? 0 : index * 0.1,
          duration: 1,
          immediateRender: true,

          scrollTrigger: { trigger: el, start: "top 60%" },
        }
      );
    });
  }, 600);
};
export const slideUpHnadler = (el, duration, delay) => {
  setTimeout(() => {
    gsap.fromTo(
      el,
      { y: "100%", display: "inline-block", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: duration,
        immediateRender: true,
        delay: delay && delay,
        ease: "fastToSlow",

        scrollTrigger: { trigger: el, start: "top 100%" },
      }
    );
  }, 0);
};
