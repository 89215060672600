// src/features/project/projectDetailSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const projectDetailAction = createAsyncThunk(
  "projectDetail/fetchProjectDetail",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const projectDetailSlice = createSlice({
  name: "projectDetail",
  initialState: {
    statusProjectDetail: "",
    dataProjectDetail: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectDetailAction.pending, (state) => {
        state.statusProjectDetail = "loading";
      })
      .addCase(projectDetailAction.fulfilled, (state, action) => {
        state.statusProjectDetail = "succeeded";
        state.dataProjectDetail = action.payload;
      })
      .addCase(projectDetailAction.rejected, (state, action) => {
        state.statusProjectDetail = "failed";
        state.error = action.error.message;
      });
  },
});

export default projectDetailSlice.reducer;
