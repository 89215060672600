import { useEffect, useState, useRef, memo } from "react";
import { useSelector } from "react-redux";
import gsap from "gsap";
import Transition from "../Compoents/GeneralComponents/PageTransition";
import { changeMenuColorBlack } from "../Compoents/MotionTools/Slide";
import { useNavigate } from "react-router-dom";
import PointerCursor from "../Compoents/GeneralComponents/PointerCursor";
import ContactSection from "../Compoents/Home/ContactSection/ContactSection";
import Meta from "../Compoents/Meta/Meta";
import FooterSection from "../Compoents/FooterSection";
import Header from "../Compoents/Header";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";
function Project() {
  const [dataFetch, setDataFetch] = useState({ body: null, seo: null });
  const { dataProject } = useSelector((state) => state.project);
  const { footer } = useSelector((state) => state.ui);
  const ref = useRef(null);
  const { locate } = useSelector((state) => state.ui);
  useEffect(() => {
    if (dataProject) {
      const seo = dataProject?.data?.attributes.SEO;
      setDataFetch({
        body: dataProject?.data?.attributes?.Projects,
        seo: seo,
      });
    }
  }, [dataProject]);

  useGSAP(
    () => {
      const animationHandler = () => {
        const imgs = gsap.utils.toArray(".img-project");

        imgs.forEach((e) => {
          gsap.fromTo(
            e,
            { yPercent: 0 },
            {
              yPercent: 20,

              scrollTrigger: {
                trigger: e,
                start: "top 30%",
                end: () => `+=${ref.current?.offsetHeight}`,
                scrub: true,
              },
            }
          );
        });

        let tl = gsap.timeline();

        tl.to(".project-title", {
          y: 0,
          duration: 1,
          ease: "fastToSlow-nb",
        });

        tl.to(
          ".project__img-container",
          {
            clipPath: "inset(0% 0% 0% 0%)", // Reveals the image fully from top to bottom
            duration: 5,
            stagger: 0.4,
            ease: "expo.out",
          },
          "<20%"
        );
      };
      gsap.fromTo(
        ".project__text-container",
        { height: 0, overflow: "hidden" },
        {
          height: "auto",
          duration: 1,
          delay: 3,

          ease: "fastToSlow-nb",
        }
      );

      let timer = setTimeout(animationHandler, 1200);

      return () => {
        clearTimeout(timer);
      };
    },
    ref.current,
    { scope: ref.current }
  );
  useGSAP(
    () => {
      setTimeout(() => changeMenuColorBlack(ref.current, "white"), 1400);
    },
    ref.current,
    { scope: ref.current }
  );

  const nav = useNavigate();

  return (
    <>
      {dataFetch.body && (
        <>
          <Header data={footer} />
          <main ref={ref} className="main-project">
            <Meta data={dataFetch.seo} />
            <div className="project__title-container">
              <h3 className="project-title">
                {locate === "it"
                  ? "I progetti più belli"
                  : "The most beautiful projects"}
              </h3>
            </div>
            <div className="project-collection">
              {dataFetch.body.map((e, index) => {
                return (
                  <div
                    onClick={() => nav(e?.Slug)}
                    key={index}
                    className="project__section-container"
                  >
                    <div className="project__img-container contain">
                      <img
                        loading="eager"
                        afterLoad={() =>
                          setTimeout(() => ScrollTrigger.refresh(), 100)
                        }
                        className="img-project"
                        src={
                          process.env.REACT_APP_URL +
                          e?.Project_Image.data?.attributes.url
                        }
                        alt="project highlights"
                      />
                    </div>
                    <div className="project__text-container">
                      <h4>{e.Project_Title}</h4>
                      <p>{e.Project_Description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
          <ContactSection />
          <FooterSection data={footer} />
        </>
      )}

      <Transition />
    </>
  );
}

export default memo(Project);
