import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import StickyParagraph from "./StickyParagraph"; // New component for paragraph handling
import NotesList from "./NotesList"; // New component for notes rendering
import { changeMenuColorBlack } from "../../MotionTools/Slide";

const StickyContent = ({ data, refPass, locate, isDataLoaded }) => {
  const [paragraphWidth, setParagraphWidth] = useState(0);
  const paragraphRef = useRef(null);

  useEffect(() => {
    const updateParagraphWidth = () => {
      if (paragraphRef.current) {
        setParagraphWidth(paragraphRef.current.offsetWidth);
      }
    };

    updateParagraphWidth();

    window.addEventListener("resize", updateParagraphWidth);
    return () => {
      window.removeEventListener("resize", updateParagraphWidth);
    };
  }, [paragraphRef.current]);

  useEffect(() => {
    if (isDataLoaded && paragraphWidth > 100) {
      let mm = gsap.matchMedia();
      mm.add("(max-width:700px)", () => {
        gsap.fromTo(
          ".section-sticky",
          { xPercent: 0 },
          {
            xPercent: locate === "it" ? -89 : -91,
            scrollTrigger: {
              trigger: refPass.current,
              start: "top -15%",
              end: () => `+=${refPass.current?.offsetHeight * 0.8}`,
              scrub: true,
            },
          }
        );
      });

      mm.add("(min-width:701px)", () => {
        gsap.fromTo(
          ".section-sticky",
          { x: 0 },
          {
            x:
              locate === "it" ? -paragraphWidth / 1.55 : -paragraphWidth / 1.48,
            scrollTrigger: {
              trigger: refPass.current,
              start: "top -15%",
              end: () => `+=${refPass.current?.offsetHeight * 0.8}`,
              scrub: true,
            },
          }
        );
      });
      changeMenuColorBlack(refPass.current, "white");
    }
  }, [isDataLoaded, paragraphWidth, refPass?.current, locate]);

  return (
    <div className="section-sticky">
      <StickyParagraph data={data} paragraphRef={paragraphRef} />
      <NotesList notes={data?.stickyTexts} />
    </div>
  );
};

export default StickyContent;
