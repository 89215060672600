// BlogDataFetcher.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blogAction } from "../../../State/Features/Blog/blogSlice";
import { blogDetailAction } from "../../../State/Features/Blog/blogDetailSlice";

const BlogDataFetcher = ({ locate, setDataFetch }) => {
  const { dataBlog, statusBlog } = useSelector((state) => state.blog);
  const { dataBlogDetail } = useSelector((state) => state.blogDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataBlog.data) {
      dispatch(blogAction(`blog?locale=${locate}`));
    }
    if (!dataBlogDetail.data) {
      dispatch(blogDetailAction(`blog-details?locale=${locate}`));
    }
  }, [locate, dataBlog.data, dataBlogDetail.data, dispatch]);

  useEffect(() => {
    if (dataBlog.data) {
      const lastestBlogs = dataBlog.data?.attributes.Blogs.slice(-3);
      setDataFetch(lastestBlogs);
    }
  }, [dataBlog.data, setDataFetch]);

  return null;
};

export default BlogDataFetcher;
