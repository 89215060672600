// src/features/ui/uiSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isReady: false,
  routes: false,
  url: null,
  position: 0,
  locate: "it",
  isTransitionMenu: false,
  isForm: false,
  footer: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    transitionHandler: (state, action) => {
      state.isTransitionMenu = action.payload;
    },
    routeHandler: (state, action) => {
      state.routes = action.payload;
    },
    loadHandler: (state) => {
      state.isLoading = true;
    },
    positionHandler: (state, action) => {
      state.position = action.payload;
    },
    apiReadyHandler: (state, action) => {
      state.isReady = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    localeHandler: (state, action) => {
      state.locate = action.payload;
    },
    isFormHanler: (state, action) => {
      state.isForm = action.payload;
    },
    footerHandler: (state, action) => {
      state.footer = action.payload;
    },
  },
});

export const {
  routeHandler,
  loadHandler,
  positionHandler,
  apiReadyHandler,
  setUrl,
  localeHandler,
  transitionHandler,
  isFormHanler,
  footerHandler,
} = uiSlice.actions;
export default uiSlice.reducer;
