import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const PrivacyAction = createAsyncThunk(
  "Privacy/fetchPrivacy",
  async (props) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/${props}&populate=deep`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  }
);

const PrivacySlice = createSlice({
  name: "Privacy",
  initialState: {
    statusPrivacy: "",
    dataPrivacy: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PrivacyAction.pending, (state) => {
        state.statusPrivacy = "loading";
      })
      .addCase(PrivacyAction.fulfilled, (state, action) => {
        state.statusPrivacy = "succeeded";
        state.dataPrivacy = action.payload;
      })
      .addCase(PrivacyAction.rejected, (state, action) => {
        state.statusPrivacy = "failed";
        state.error = action.error.message;
      });
  },
});

export default PrivacySlice.reducer;