// src/features/blog/blogSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const blogAction = createAsyncThunk("blog/fetchBlog", async (props) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API}/${props}&populate=deep`,
    {
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
  );
  return response.data;
});

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    statusBlog: "",
    dataBlog: {},
    error: undefined,
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogAction.pending, (state) => {
        state.statusBlog = "loading";
      })
      .addCase(blogAction.fulfilled, (state, action) => {
        state.statusBlog = "succeeded";
        state.dataBlog = action.payload;
      })
      .addCase(blogAction.rejected, (state, action) => {
        state.statusBlog = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogSlice.reducer;
